import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { showToast } from "redux/General/Actions";
import List from '@material-ui/core/List';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import sideBarLogo from '../../../Assets/sideBarLogo.png';
import sideBarLogo2 from '../../../Assets/sideBarLogo2.png';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Routes from "Routes/RoutesList";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { logOutUser } from "redux/auth/actions";
import { connect, useDispatch } from 'react-redux';
import Api from "Helpers/ApiHandler";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            position: "absolute",
          },
    },
    appBar: {
        backgroundColor: 'transparent',
        // width: "50%",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    paperLight: {
        background: "#D85767",
        color: 'white'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: theme.spacing(0, 0),
        margin: "20px 4px 0px 4px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        // width: "90%",
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    icon: {
        color: '#FFFFFF',
        marginLeft: "5px"
    }
}));

function Sidebars(props) {
    // let [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    let [routes, setRoutes] = useState([]);
    let [openSubmenu, setOpenSubmenu] = useState("");
    const accessLevel = localStorage.getItem("access_level");
    const classes = useStyles();
    // const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        // let levelRoutes = Routes.filter((route) => route.sidebar)
        // setRoutes(levelRoutes);
        if (accessLevel == "Level 1") {
            let levelOneRoutes = Routes.filter((routes) => routes?.sidebar?.name === "Data Entry" || routes?.sidebar?.filterName === "Child Management" || routes?.sidebar?.name === "Tasks" || routes?.sidebar?.name === "Reports" || routes?.sidebar?.name === "Incidents" || routes?.sidebar?.name === "Alerts" || routes?.sidebar?.name === "Help" || routes?.sidebar?.name === "Dashboard" || routes?.sidebar?.name === "Collaborate With Users");
            setRoutes(levelOneRoutes);
        }
        else if (accessLevel == "Level 2" || accessLevel == "Level 3" || accessLevel == "Level 5" || accessLevel == "Level 6" || accessLevel == "Level 8" || accessLevel == "Level 12" || accessLevel == "Level 13" || accessLevel == "Level 14" || accessLevel == "Level 15" || accessLevel == "Level 16") {
            let restrictedRoutes = Routes.filter((routes) => routes?.sidebar?.filterName === "Child Management Restricted"  || routes?.sidebar?.name === "Help" || routes?.sidebar?.name === "Dashboard"  || routes?.sidebar?.name === "Tasks"  || routes?.sidebar?.name === "Incidents" || routes?.sidebar?.name === "Alerts");
            setRoutes(restrictedRoutes);
        }
        else if (accessLevel === "Level 9") {
            let levelNineRoutes = Routes.filter((routes) => routes?.sidebar?.name === "Help" || routes?.sidebar?.name === "Data Entry" || routes?.sidebar?.name === "Dashboard");
            setRoutes(levelNineRoutes);
        }
        else if (accessLevel === "Level 11") {
            let levelElevenRoutes = Routes.filter((routes) => routes?.sidebar?.name === "Administrator" || routes?.sidebar?.name === "Help" || routes?.sidebar?.name === "Dashboard");
            // console.log(levelElevenRoutes)
            setRoutes(levelElevenRoutes);
        } 
        else if (accessLevel === "Level 19") {
            let levelOneNineRoutes =  Routes.filter((routes) => routes?.sidebar?.name === "Data Entry" || routes?.sidebar?.name === "Help" || routes?.sidebar?.filterName === "Child Management" || routes?.sidebar?.name === "Dashboard");
            setRoutes(levelOneNineRoutes);
        }
        else if (accessLevel === "super") {
            let filterRoutes = Routes.filter((route) => route?.sidebar?.filterName !== "Child Management Restricted" )
            let superRoutes = filterRoutes.filter((route) => route.sidebar)
            setRoutes(superRoutes);
        }
        else {
            let route = Routes.filter((routes) => routes?.sidebar?.name === "Tasks" || routes?.sidebar?.name === "Dashboard" || routes?.sidebar?.name === "Incidents" || routes?.sidebar?.name === "Alerts" || routes?.sidebar?.name === "Help");
            // console.log(route)
            setRoutes(route);
        }
    }, [accessLevel])
    // console.log(routes)
    function listItemClick(route) {
        if (route.sidebar.nested) {
            setOpenSubmenu((route.path !== openSubmenu) ? route.path : "")
            return;
        }
        if(route.path === "/reports"){
            props.history.push({pathname : route.path, state : "side menu report"})
        } else {
            props.history.push(route.path)
        }
        
    }

    async function onConfirm() {
        try {
            // setLoading(true);
            await new Api().post("/admin/sign-out", {
                returnUnhandledError: true,
                returnError: true
            })
            props.logOutUser();
            // setLoading(false);
        }
        catch (error) {
            dispatch(showToast(error.message && error.message, null));
            // setLoading(false);
            console.log(
                "TCL ~ file: Drawer.jsx ~ line 25 ~ formSubmit ~ error",
                error
            );
        }
    }

    return (
        <div className={classes.root}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.paperLight, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    {open ?
                        <IconButton
                            onClick={handleDrawerClose}
                        >
                            <img src={sideBarLogo2} alt="" />
                        </IconButton> :
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                        >
                            <img src={sideBarLogo} alt="" />
                        </IconButton>
                    }
                </div>
                <div className="list-container">
                    <List style={{ marginTop: "20px" }}>
                        {/* {console.log(routes)} */}
                        {
                            routes.map((route) =>
                                <React.Fragment key={route.path}>
                                    {
                                        route.sidebar.showDivider &&
                                        <Divider />
                                    }
                                    <ListItem
                                        button
                                        selected={route.path === props.history.location.pathname}
                                        onClick={() => { listItemClick(route) }}>
                                        <ListItemIcon className={classes.icon}>
                                            {route.sidebar.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={route.sidebar.name} />
                                        {route.sidebar.nested &&
                                            <>
                                                {
                                                    (openSubmenu === route.path) ? <ExpandLess /> : <ExpandMore />
                                                }
                                            </>
                                        }
                                    </ListItem>

                                    {
                                        route.sidebar.nested &&
                                        <Collapse in={openSubmenu === route.path} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {
                                                    route.sidebar.nested.map((child) =>
                                                        <ListItem
                                                            button
                                                            className="nested"
                                                            key={child.path}
                                                            selected={child.path === props.history.location.pathname}
                                                            onClick={() => { props.history.push(child.path) }}
                                                        >
                                                            <ListItemIcon>
                                                                {child.icon}
                                                            </ListItemIcon>
                                                            <ListItemText primary={child.name} />
                                                        </ListItem>
                                                    )
                                                }
                                            </List>
                                        </Collapse>
                                    }

                                </React.Fragment>
                            )
                        }
                        <ListItem
                            style={{ marginTop: "15px" }}
                            button
                            onClick={() => onConfirm()}>
                            <ListItemIcon className={classes.icon}>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Logout"} />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        </div>
    );
}

// export default withRouter(Sidebars);
export default connect(null, {
    logOutUser
})(withRouter(Sidebars));
