import * as React from 'react';
import { useState, useEffect } from 'react';
import { Modal, Stack, Dialog, Paper } from '@mui/material';
import PropTypes from "prop-types";
import {
    FormControl, FormLabel, TextField, Autocomplete, IconButton,
    Tooltip,
} from "@mui/material";
import { Add, Remove } from '@mui/icons-material';
import { InputWrapper, useStyles } from "./Commons.style";
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#808080',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: "#fff",
}));

function BasicModal(props) {
    let [open, setOpen] = React.useState(false);
    let { width, onConfirm, onSuccess, loading, submitText, children, ...rest } = props;

    React.useEffect(() => {
        if (props.open) {
            setOpen(true);
        }
    })
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {children}
            </Modal>
        </div>
    );
}
function InputWithOutLabel(props) {
    let { label, fieldType, ...rest } = props;
    const classes = useStyles();

    return (
        <FormControl
            component="fieldset"
            style={{ width: "100%", marginBottom: "10px" }}
        >
            {fieldType?.type === "AutoComplete" ?
                <Autocomplete
                    // {...rest}
                    style={{
                        width: "100%"
                    }}
                    className={classes.rootAutoComplete}
                    disablePortal
                    multiple={fieldType?.multiple}
                    size="small"
                    fullWidth={true}
                    onChange={(e, value) => (console.log("value", value), rest.setFieldValue(rest.name, value))}
                    options={fieldType?.data && fieldType?.data}
                    getOptionLabel={option => option?.label}
                    sx={{ width: 400 }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            {...rest}
                            className={classes.root}
                            variant={"filled"}
                            // autoComplete='off'
                            // margin="dense"
                            fullWidth
                        />
                    }
                />
                :
                <>
                    <TextField
                        {...rest}
                        className={classes.root}
                        variant={"filled"}
                        fullWidth
                    />
                </>
            }
            <div className={classes.rootErrorWithOutLabel}>{props.validated}</div>
        </FormControl>
    )
}
function InputWithLabel(props) {
    let { label, fieldType, setfieldvalue, getfieldprops, ...rest } = props,
        [count, setCount] = useState(1),
        [counts, setCounts] = useState([]);
    const classes = useStyles();
    function handleQuestionField() {
        let counting = rest.onQuestionFieldAdd();
        localStorage.setItem("counting", counting);
        setCount(counting);
    }

    useEffect(() => {
        let newCounting = localStorage.getItem("counting");
        setCount(newCounting ? newCounting : 1);
    }, [count]);
    return (
        <FormControl
            component="fieldset"
            style={{ width: "100%", marginBottom: "10px" }}
        >
            <FormLabel component="legend">
                {label}
            </FormLabel>
            {fieldType?.type === "AutoComplete" ?
                <Autocomplete
                    // {...rest}
                    style={{
                        width: "100%"
                    }}
                    id={rest.name}
                    disablePortal
                    multiple={fieldType?.multiple}
                    size="small"
                    fullWidth={true}
                    onChange={(e, value) => (console.log("value", value), setfieldvalue(rest.name, value))}
                    options={fieldType?.data ? fieldType?.data : []}
                    value={rest?.value && rest?.value}
                    getOptionLabel={option => option?.label}
                    sx={{ width: 400 }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            {...rest}
                            className={classes.root}
                            name={rest.name}
                            // variant={"filled"}
                            autoComplete='off'
                            margin="dense"
                            fullWidth
                        />
                    }
                />
                :
                <>
                    <TextField
                        {...rest}
                        className={classes.root}
                        fullWidth
                    />

                </>
            }
            <div className={classes.rootError}>{props.validated}</div>
            {rest.name === `answer${count}` &&
                <div style={{ display: "flex", marginTop: "5px" }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        onClick={() => handleQuestionField()}
                    >
                        <Item>{"Add More Security Question"}</Item>
                    </Stack>
                </div>}
        </FormControl>
    )
}

function BasicDialog(props) {
    let [open, setOpen] = React.useState(false);
    let { width, onConfirm, onSuccess, onClose, loading, submitText, children, ...rest } = props;
    React.useEffect(() => {
        if (props.open) {
            setOpen(true);
        }
    })
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Dialog open={open} onClose={onClose}>{children}</Dialog>
        </div>
    );
}


// BasicModal.propTypes = {
//     title: PropTypes.string.isRequired,
//     onClose: PropTypes.func.isRequired,
//     visible: PropTypes.bool.isRequired,
//     submitText: PropTypes.string,
// };

export { BasicModal, BasicDialog, InputWithLabel, InputWithOutLabel };
