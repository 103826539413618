//react redux
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//material ui
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//utils
import { BasicDialog } from "Components/Commons/Commons";
import LoaderButton from "Components/Commons/Loader/LoaderButton";
import { InputWithLabel } from "Components/Commons/Commons";
import { loginUser } from "redux/auth/actions";
import Api from "Helpers/ApiHandler";

//styles
import { useStyles } from "./QuestionDialog.style";
import { toMonthName } from "Helpers";
function PromotionDialog(props) {
  
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
 

  return (
    <div className={classes.container}>
      <BasicDialog open={open} onClose={props.onSuccess} {...props}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent className={classes.btnAction}>
        You are in the month of {toMonthName(props?.data?.pmonth)}, Please remember to exexute PROMOTIONS before adding new registrations on the system
        <DialogActions>
                  <LoaderButton
                    type="submit"
                    onConfirm={props.onSuccess}
                    onClick={props.onSuccess}
                    className={classes.btnItem}
                    variant="contained"
                    loading={loading}
                    width="120px"
                    height="40px"
                  >
                    CONTINUE
                  </LoaderButton>
                </DialogActions>
        </DialogContent>
      </BasicDialog>
    </div>
  );
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
});

export default connect(mapReduxStateToProps, {
  loginUser,
})(withRouter(PromotionDialog));
