import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { store } from "redux/store";
import { showToast } from "redux/General/Actions";
import { Formik, Form } from "formik";
import { generateValidationSchema } from "Helpers/Utils";
import { InputWithOutLabel } from "Components/Commons/Commons";

// DESIGN
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import LoaderButton from '../../Commons/Loader/LoaderButton';
import Flickity from "react-flickity-component";
import "./flickity.css";

// STYLES
import { ForgotPasswordWrapper, ForgotPasswordBox, OurPartnerBox } from "./ForgotPassword.style";

// CONSTANTS
import { URL_DASHBOARD, URL_RESET_PASSWORD, URL_OTP } from "Helpers/Paths";
import Api from "Helpers/ApiHandler";

// IMAGE
import NCRLogo from "../../../Assets/ncrLogo.png";
import GovttLogo from "../../../Assets/gov.png";
import Partner1 from '../../../Assets/logo1 1.png';
import Partner2 from '../../../Assets/logo3 1.png';
import Partner3 from '../../../Assets/logo6 1.png';
import Partner4 from '../../../Assets/logo2 1.png';
import Partner5 from '../../../Assets/logo5 1.png';
import Partner6 from '../../../Assets/logo4 1.png';
import Hexagones from '../../../Assets/Hexagones.png';
import Tabs from "Components/Commons/Tabs/BasicTabs";

function ForgotPassword(props) {
    let [questionList, setQuestionList] = useState([]),
        [inputs, setInputs] = useState([{
            label: "Email",
            name: "email",
            validation: { required: true, email: true },
            fieldType: ""
        }]),
        [questionInputs, setQuestionInputs] = useState([])
    const initialValues = {
        email: "",
    };

    const initialQuestionValues = {
        email: "",
        question: "",
        answer: ""
    };

    const validationSchema = generateValidationSchema(inputs);
    const QuestionvalidationSchema = generateValidationSchema(questionInputs);
    useEffect(() => {
        getQuestionList()
    }, []);

    useEffect(() => {
        setQuestionInputs([{
            label: "Email",
            name: "email",
            validation: { required: true, email: true },
            fieldType: ""
        }, {
            label: "Question",
            name: "question",
            validation: { required: true, object: true },
            fieldType: { type: "AutoComplete", multiple: false, data: questionList }
        }, {
            label: "Answer",
            name: "answer",
            validation: { required: true },
            fieldType: ""
        }])
    }, [questionList]);

    async function getQuestionList() {
        try {
            setLoading(true);
            let response = await new Api().get(`/admin/question/list`, {
                returnUnhandledError: true,
                returnError: true
            });
            let questionList = response.data.rows.map((item) => {
                let dataValues = { ...item };
                dataValues.label = item.question
                return dataValues
            })
            setQuestionList(questionList);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error:", error);
        }
    }
    const useStyles = makeStyles((theme) => ({
        heading: {
            color: "#1C1B1B",
            fontSize: "18px",
            lineHeight: "20px"
        },
        rememberButton: {
            '& .MuiTypography-body1': {
                fontSize: "12px"
            }
        },
        forgotButton: {
            fontSize: "12px"
        },
        otp: {
            fontSize: "14px",
            marginTop: "10px",
            "& .forgot-password": {
                textDecoration: "none",
                color: "#1C1B1B"
            }
        },
        formContainer: {
            width: "100% !important"
        },
        root: {
            marginTop: '-52px',
            '& .MuiFilledInput-root': {
                backgroundColor: "#FFFFFF",
            },
            '& .MuiFilledInput-underline:before': {
                borderBottom: "none"
            },
            '& .MuiFilledInput-underline': {
                border: "1px solid #DDDBE8",
                borderRadius: "12px",
                height: "45px"
            },
            '& .MuiFilledInput-underline:after': {
                borderBottom: "none"
            },
            'input: -internal - autofill - selected': {
                backgroundColor: "#DDDBE8 !important"
            },
            '& .MuiFilledInput-input': {
                padding: "0px 12px 0px",
                '&:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 100px #FFFFFF inset',
                    '-webkit-text-fill-color': '#1C1B1B'
                }
            },
            '& .MuiFilledInput-input:-webkit-autofill': {
                borderTopLeftRadius: "inherit",
                borderTopRightRadius: "inherit",
                borderBottomLeftRadius: "inherit",
                borderBottomRightRadius: "inherit",
            }
        }
    }))

    const classes = useStyles();
    const [email, setEmail] = useState('');

    let [loading, setLoading] = useState(false);


    const flickityOptions = {
        // initialIndex: 2,
        asNavFor: ".carousel.carousel-main",
        pageDots: true,
        fade: true,
        imagesLoaded: true,
        groupCells: true,
        wrapAround: true,
        // pauseAutoPlayOnHover: true,
        autoPlay: 4000,
        // prevNextButtons: true
        // resize: false
        // cellAlign: 'left'
    }

    const images = [Partner1, Partner2, Partner3, Partner4, Partner5, Partner6];

    useEffect(() => {
        checkIfAlreadyLoggedIn();
        // eslint-disable-next-line
    }, [])

    function checkIfAlreadyLoggedIn() {
        if (props.isLoggedIn)
            props.history.push(URL_DASHBOARD)
    }
    const handleSecurityQuestionSubmit = async (values) => {
        var details = {
            'email': values.email,
            'questionId': values.question.id,
            'answer': values.answer
        }

        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        try {
            setLoading(true);
            let config = {
                data: formBody,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                returnUnhandledError: true,
                returnError: true,
            }
            await new Api().post("/admin/securitycheck", config);
            props.history.push({
                pathname: URL_RESET_PASSWORD,
                state: values.email
            });
            setLoading(false);
        } catch (error) {
            store.dispatch(showToast(error.message && error.message, null));
            setLoading(false);
            console.log(
                "TCL ~ file: Drawer.jsx ~ line 25 ~ formSubmit ~ error",
                error
            );
        }
    }
    const handleSubmit = async (values) => {
        var details = {
            'email': values.email
        }

        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        try {
            setLoading(true);
            let config = {
                data: formBody,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                returnUnhandledError: true,
                returnError: true,
            }
            await new Api().post("/admin/send-otp", config);
            props.history.push({
                pathname: URL_OTP,
                state: values.email
            })
            setLoading(false);
        } catch (error) {
            store.dispatch(showToast(error.message && error.message, null));
            setLoading(false);
            console.log(
                "TCL ~ file: Drawer.jsx ~ line 25 ~ formSubmit ~ error",
                error
            );
        }
    }

    const content = {
        tabLabel: ["Send Otp", "Security Question"],
        children: [
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({ values, touched, errors, handleChange }) =>
                (
                    <Form className={classes.root}>
                        {
                            inputs.map(input =>
                                <InputWithOutLabel
                                    className="classes.root"
                                    name={input.name}
                                    fieldType={input.fieldType}
                                    placeholder={input.label}
                                    label={input.label}
                                    type={input.type}
                                    value={values[input.name]}
                                    onChange={handleChange}
                                    validated={touched[input.name] && errors[input.name]}
                                />
                            )
                        }
                        <div className="button-container" style={{ marginTop: "20px" }}>
                            <LoaderButton
                                type="submit"
                                className={"btn submit-btn-item"}
                                variant="contained"
                                loading={loading}
                                type={"submit"}
                            >
                                {"Send OTP"}
                            </LoaderButton>
                        </div>
                    </Form>)
                }
            </Formik>
            ,
            <Formik
                initialValues={initialQuestionValues}
                onSubmit={handleSecurityQuestionSubmit}
                validationSchema={QuestionvalidationSchema}
            >
                {({ values, touched, errors, handleChange, setFieldValue }) =>
                (
                    <Form className={classes.root}>
                        {
                            questionInputs.map(input =>
                                <InputWithOutLabel
                                    className="classes.root"
                                    name={input.name}
                                    fieldType={input.fieldType}
                                    placeholder={input.label}
                                    label={input.label}
                                    type={input.type}
                                    value={values[input.name]}
                                    onChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    validated={touched[input.name] && errors[input.name]}
                                />
                            )
                        }
                        <div className="button-container">
                            <LoaderButton
                                type="submit"
                                className={"btn submit-btn-item"}
                                variant="contained"
                                loading={loading}
                                type={"submit"}
                                height={"40"}
                            >
                                {"Verify"}
                            </LoaderButton>
                        </div>
                    </Form>)
                }
            </Formik>
        ]
    }
    return (
        <ForgotPasswordWrapper className="flexs">
            <ForgotPasswordBox className="box">
                <div className="logoWrapper">
                    <Link to="/dashboard">
                        <img src={NCRLogo} alt="LOGO" />
                    </Link>
                    <img src={GovttLogo} alt="LOGO" />
                </div>
                <Typography align='left' variant="h6" >
                    Forgot Password
                </Typography>
                <Typography align='left' variant="subtitle2" style={{ color: "#AAAAAA" }}>
                    You can reset your passsword here.
                </Typography>
                <Tabs data={content} {...props}>

                </Tabs>
            </ForgotPasswordBox>
            <OurPartnerBox >
                <h1>Our Partners</h1>
                <Flickity className={'carousel'}
                    elementType={'div'}
                    options={flickityOptions}
                    disableImagesLoaded={false}
                    reloadOnUpdate
                    static
                >
                    <div class="carousel-cell carousel-cell-1">
                        <img class="carousel-cell-image" src={Partner1} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-2">
                        <img class="carousel-cell-image" src={Partner2} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-3">
                        <img class="carousel-cell-image" src={Partner3} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-4">
                        <img class="carousel-cell-image" src={Partner4} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-5">
                        <img class="carousel-cell-image" src={Partner5} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-6">
                        <img class="carousel-cell-image" src={Partner6} alt="partner 1" />
                    </div>
                </Flickity>
                <img src={Hexagones} className='hexagons-img' alt="" />
                <Typography align='center' variant="subtitle2" style={{ marginTop: "30px", color: "white" }}>
                    Copyright © 2021 by Gortt
                </Typography>
            </OurPartnerBox>
        </ForgotPasswordWrapper>
    )
}

export default ForgotPassword;
