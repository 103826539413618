export const Messages = {
    validation: {
        email: {
            valid: "Ex user@mail.com",
            required: "Please enter your email address."
        },
        firstName: {
            required: "Please enter your first name."
        },
        lastName: {
            required: "Please enter your last name."
        },
        ministryDivision: {
            required: "Please enter ministry division."
        },
        group: {
            required: "Please enter group."
        },
        securityQuestion1: {
            required: "Please enter security question 1."
        },
        accessLevel: {
            required: "Please enter your access level."
        },
        answer1: {
            required: "Please enter your answer."
        },
        organizationPhoneNumber: {
            required: "Please enter your organization Phone Number.",
            number: "Must be numbers only",
            positive: "Should be in Positive",
            integer: "Type without . (dots)",
            valid: "Please enter valid Phone Number."
        },
        otp: {
            required: "Please enter your OTP.",
            number: "Must be numbers only",
            positive: "Should be in Positive",
            integer: "Type without . (dots)"
        },
        question: {
            required: "Please select your question."
        },
        answer: {
            required: "Please enter your answer."
        },
        phoneNumber: {
            required: "Please enter your phone number.",
            number: "Must be numbers only",
            positive: "Should be in Positive",
            integer: "Type without . (dots)",
            valid: "Please enter valid phone number"
        },
        password: {
            required: "Please enter your password",
            // eslint-disable-next-line
            min: "Password must be atleast ${min} length"
        },
        currentPassword: {
            required: "Please enter your current password",
            // eslint-disable-next-line
            min: "Password must be atleast ${min} length"
        },
        confirmPassword: {
            required: "Please retype your password",
            equals: "Password doesn't match"
        },
        projectName: {
            required: "Please enter project name"
        },
        subject: {
            required: "Please enter subject name"
        },
        message: {
            required: "Please enter message name"
        }
    }
}
