export const ACTION_TYPES = {
    TOAST_MESSAGE_ENABLE: "TOAST_MESSAGE_ENABLE",
    TOAST_MESSAGE_DISABLE: "TOAST_MESSAGE_DISABLE"
}

export const showToast = (message, duration, showIcon, data) => {
    return {
        type: ACTION_TYPES.TOAST_MESSAGE_ENABLE,
        message: message || '',
        duration: duration && duration,
        showIcon: showIcon && showIcon,
        data: data && data
    }
}
export const hideToast = () => {
    return {
        type: ACTION_TYPES.TOAST_MESSAGE_DISABLE
    }
}

