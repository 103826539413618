import io from 'socket.io-client';

let socketTest;

const END_URL = process.env.REACT_APP_SOCKET_URL;

const connectSocket = () => {
    const credentials = {
        AccessToken: localStorage.getItem('accessToken')
    };

    socketTest = io(END_URL, {
        transports: ['websocket'],
        query: credentials,
        autoConnect: false,
        reconnection: true,
        reconnectionAttempts: 5
    });


};

export { connectSocket, socketTest };
