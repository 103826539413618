import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) =>({
    root: {
        width: "100%",
        backgroundColor: "#F5F6FA",
        marginTop: "auto",
        // position: "relative",
       
        [theme.breakpoints.up('xs')]: {
            position: "relative",
        },
        [theme.breakpoints.up('sm')]: {
            position: "absolute",
        },
        // position: "fixed",
        right: 0,
        bottom: 0,
        left: 0,
        padding: "1rem",
        bottom: 0,
    },
    bottom: {
        color: "#1C1B1B",
    }
}));

export default function Footers() {
    const classes = useStyles();
    return (
        <BottomNavigation
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction className={classes.bottom} label="Copyright © 2021 by Gortt" />
        </BottomNavigation>
    );
}
