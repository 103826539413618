import styled from "styled-components";

export const ForgotPasswordWrapper = styled.div`
    height  : calc(100vh - 30px);
    width   : 100%;
`

export const ForgotPasswordBox = styled.div`
    // background      : #fff;
    // border-radius   : 5px;
    // box-shadow      : 0px 0px 20px rgba(92,111,139,0.12);
    padding         : 30px;
    width           : 100%;
    padding: 30px 145px 40px 145px;
    
    .logoWrapper {
        width: 100%;
        display: flex;
        margin-bottom: 25px;
        justify-content: space-between;
        flex-shrink: 0;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
      }
         a img {
        height: 55px;
      }
      img {
        height: 50px;
      }
    }

    .form-container {
        margin  : 50px auto 0px;
        width   : 50%;
        display: flex;
        justify-content: space-between;

        .form-item {
            margin: 25px 15px 25px 0px;
            width: 100%;
        }
    }
    .remember-me-and-forgot-pass {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #1C1B1B;
          border-color: #1C1B1B;
        }
        a {
          text-decoration: none;
          color: #D85767;
          font-weight: 500;
        }
      }
    .button-container {
        /* margin-bottom   : 25px; */
        text-align      : center;
        font-size: 12px;
        // margin: 20px;

        .btn {
            min-width   : 115px;
            min-height  : 25px;
        }
        .submit-btn-item {
            width: max-content;
            width: 540px;
            height: 40px;
            background-color: #D85767;
            border: none;
            font-size: 14px;
            border-radius: 10px;
        }
        .terms-and-disclaimer {
            display: flex;
            justify-content: center;
            a {
                text-decoration: none;
            color:  #D85767;
            }
      }
    }
`

export const OurPartnerBox = styled.div`
    background-color: #D85767;
    flex: 1 1 auto;
    padding: 10px 145px 50px 145px;
    position: relative;
    height: 230px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    h1 {
     font-size: 20px;
    color: #FFFFFF;
    margin-bottom:0;
  }

   .flickity-prev-next-button.previous {
    left:-10px;
  }
.flickity-prev-next-button.next {
  right:-10px;
}
  .carousel {
    margin-top:20px;
    z-index:1;
}

.carousel-cell {
  width: 30%;
  height: 110px;
  margin-right: 10px;
  background: #D85767;
  border-radius: 5px;
  //  display: flex;
  align-items: center;
  // justify-content: center;
}
.carousel-cell-image {
  // display: block;
  max-height: 100%;
  height:80%;
}
.carousel-cell.is-selected {
  background: #FFFFFF;
}

/* cell number */
.carousel-cell:before {
  // display: block;
  text-align: center;
  // content: counter(carousel-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}


  .partner-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .partner-container {
      background-color: white;
      width: 350px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-top: 30px;
      .image-container img {
        height: 80px;
      }
    }
  }

  .hexagons-img {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    top:215px;
    color: white;
    p {
      text-align: center
    }
  }

`;