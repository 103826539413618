import { logOutUser } from "redux/auth/actions";
import * as Yup from "yup";
import { Messages } from "./Messages";
import Api from "Helpers/ApiHandler";
import { store } from "redux/store";
import moment from "moment";

export const isEqualsArray = (arr1, arr2) => {
    if (!(arr1 instanceof Array) || !(arr1 instanceof Array))
        return false;

    if (arr1.length !== arr2.length)
        return false;

    if (arr1 instanceof Object && arr2 instanceof Object) {
        let isEqual = true, arrayLength = arr1.length;
        for (let index = 0; index < arrayLength && isEqual; index++) {
            if (JSON.stringify(arr1[index]) !== JSON.stringify(arr2[index]))
                isEqual = false;
        }

        return isEqual;
    }

    return false;
}

export const utcLocalDateTime = (date,format) =>  moment.utc(date).local().format(format?format:'YYYY-MM-DD HH:mm:ss');

export function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
  
    return date.toLocaleString('en-US', {
      month: 'long',
    });
  }

export const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

export function generateValidationSchema(inputs) {
    let validationObject = {}

    inputs.forEach(input => {
        let { validation, name } = input;
        if (input.validation) {
            let yup = (validation.number) ? Yup.number() : (validation.array) ? Yup.array() : (validation.object) ? Yup.object().nullable() : Yup.string();

            /* GENERAL */

            if (validation.required)
                yup = yup.required(Messages.validation[name].required)

            if (validation.equals)
                yup = yup.oneOf([Yup.ref(validation.equals), null], Messages.validation[name].equals)

            /* NUMBER TYPE */
            if (validation.number)
                yup = yup.typeError(Messages.validation[name].number)

            if (validation.min)
                yup = yup.min(validation.min, Messages.validation[name].min)

            if (validation.positive)
                yup = yup.positive(Messages.validation[name].positive)

            if (validation.integer)
                yup = yup.integer(Messages.validation[name].integer)


            /* SPECIFIC */
            if (validation.email)
                yup = yup.email(Messages.validation[name].valid)


            if (validation.phoneNumber)
                yup = yup.matches(phoneRegExp, Messages.validation[name].valid)

            validationObject[name] = yup;
        }
    });

    return Yup.object(validationObject)
}

export function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function isIEBrowser() {
    // BROWSER CHECK VARIABLES
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const msie11 = ua.indexOf('Trident/');
    // const msedge = ua.indexOf('Edge/');
    return msie > 0 || msie11 > 0;
    // const isEdge = msedge > 0;
}


export function setPaginationObject(currentPagination, pagination, filters, sorter,) {
    let newPaginationInfo = {
        ...currentPagination,
        current: isEmpty(pagination) ? 1 : pagination.current,
        pageSize: isEmpty(pagination) ? 10 : pagination.pageSize,
    }

    if (sorter.columnKey === "subInterestCount") {
        sorter.field = sorter.columnKey
    }

    if (filters) {
        newPaginationInfo.search = [];
        for (const key in filters) {
            if (filters[key]) {
                newPaginationInfo.search.push({
                    field: key,
                    value: filters[key][0]
                })
            }
        }
    }

    if (sorter.field) {
        newPaginationInfo.sort = {
            field: sorter.field,
            order: (sorter.order === 'ascend') ? 'asc' : 'desc'
        }
    }

    return newPaginationInfo
}


export const disableOnTrue = (flag, name) => {

    return {
        pointerEvents: flag ? 'none' : 'initial'
    }
}

export const dimOnTrue = (flag) => {
    return {
        opacity: flag ? 0.40 : 1,
    }
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export class IdleTimer {
    constructor({ timeout, onTimeout, onExpired }) {
        this.timeout = timeout;
        this.onTimeout = onTimeout;
        this.onUserLogout = this.onUserLogout.bind(this);
        // let [loading, setLoading] = useState(false);
        const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
        if (expiredTime > 0 && expiredTime < Date.now()) {
            onExpired();
            return;
        }

        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
    }
    async onUserLogout() {
        try {
            await new Api().post("/admin/sign-out", {
                returnUnhandledError: true,
                returnError: true
            })
            store.dispatch(logOutUser());
        }
        catch (error) {
            console.log(
                "TCL ~ file: Drawer.jsx ~ line 25 ~ formSubmit ~ error",
                error
            );
        }
    }
    startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
            if (expiredTime < Date.now()) {
                if (this.onTimeout) {
                    this.onTimeout();
                    this.cleanUp();
                }
            }
        }, 1000);
    }

    updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
        }, 300);
    }

    tracker() {
        // console.log("entertracker");
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    cleanUp() {
        // console.log("enterclean");
        // localStorage.removeItem("_expiredTime");
        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("isLoggedIn");
        this.onUserLogout();
        // localStorage.clear();
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
}
// export default IdleTimer;
