import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { store } from "redux/store";
import { showToast } from "redux/General/Actions";

// DESIGN
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography } from "@material-ui/core";
import LoaderButton from '../../Commons/Loader/LoaderButton';
import Flickity from "react-flickity-component";
import "./flickity.css";

// STYLES
import { ForgotPasswordWrapper, ForgotPasswordBox, OurPartnerBox } from "./ForgotPassword.style";

// CONSTANTS
import { URL_DASHBOARD, URL_LOGIN } from "Helpers/Paths";
import Api from "Helpers/ApiHandler";

// IMAGE
import NCRLogo from "../../../Assets/ncrLogo.png";
import GovttLogo from "../../../Assets/gov.png";
import Partner1 from '../../../Assets/logo1 1.png';
import Partner2 from '../../../Assets/logo3 1.png';
import Partner3 from '../../../Assets/logo6 1.png';
import Partner4 from '../../../Assets/logo2 1.png';
import Partner5 from '../../../Assets/logo5 1.png';
import Partner6 from '../../../Assets/logo4 1.png';
import Hexagones from '../../../Assets/Hexagones.png'

function ResetPassword(props) {
    const useStyles = makeStyles((theme) => ({
        heading: {
            color: "#1C1B1B",
            fontSize: "18px",
            lineHeight: "20px"
        },
        rememberButton: {
            '& .MuiTypography-body1': {
                fontSize: "12px"
            }
        },
        forgotButton: {
            fontSize: "12px"
        },
        formContainer: {
            width: "100% !important"
        },
        root: {
            marginTop: '-52px',
            '& .MuiFilledInput-root': {
                backgroundColor: "#FFFFFF",
            },
            '& .MuiFilledInput-underline:before': {
                borderBottom: "none"
            },
            '& .MuiFilledInput-underline': {
                border: "1px solid #DDDBE8",
                borderRadius: "12px",
                height: "50px"
            },
            '& .MuiFilledInput-underline:after': {
                borderBottom: "none"
            },
            'input: -internal - autofill - selected': {
                backgroundColor: "#DDDBE8 !important"
            },
            '& .MuiFilledInput-input': {
                padding: "0px 12px 0px",
                '&:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 100px #FFFFFF inset',
                    '-webkit-text-fill-color': '#1C1B1B'
                }
            },
            '& .MuiFilledInput-input:-webkit-autofill': {
                borderTopLeftRadius: "inherit",
                borderTopRightRadius: "inherit",
                borderBottomLeftRadius: "inherit",
                borderBottomRightRadius: "inherit",
            }

        }
        // "& .MuiButton-contained": {
    }))

    const classes = useStyles();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    let [loading, setLoading] = useState(false);


    const flickityOptions = {
        asNavFor: ".carousel.carousel-main",
        pageDots: true,
        fade: true,
        imagesLoaded: true,
        groupCells: true,
        wrapAround: true,
        autoPlay: 4000,
    }

    useEffect(() => {
        checkIfAlreadyLoggedIn();
        // eslint-disable-next-line
    }, [])

    function checkIfAlreadyLoggedIn() {
        if (props.isLoggedIn)
            props.history.push(URL_DASHBOARD)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var details = {
            'email': props.location.state,
            'password': newPassword,
            'confirmPassword': confirmPassword
        };

        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        try {
            setLoading(true);
            let config = {
                data: formBody,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                returnUnhandledError: true,
                returnError: true,
            }
            await new Api().post("/admin/reset-password", config);
            props.history.push(URL_LOGIN);
            setLoading(false);
        } catch (error) {
            store.dispatch(showToast(error.message && error.message, null));
            setLoading(false);
            console.log(
                "TCL ~ file: Drawer.jsx ~ line 25 ~ formSubmit ~ error",
                error
            );
        }
    }

    return (
        <ForgotPasswordWrapper className="flexs">
            <ForgotPasswordBox className="box">
                <div className="logoWrapper">
                    <Link to="/dashboard">
                        <img src={NCRLogo} alt="LOGO" />
                    </Link>
                    <img src={GovttLogo} alt="LOGO" />
                </div>
                <Typography align='left' variant="h6" >
                    Forgot Password
                </Typography>
                <Typography align='left' variant="subtitle2" style={{ color: "#AAAAAA" }}>
                    You can reset your passsword here.
                </Typography>
                <form className={classes.root} onSubmit={handleSubmit} novalidate>
                    <div className={clsx("form-container", { [classes.formContainer]: true })}>
                        <div className={clsx("form-item", classes.division)}>
                            <TextField
                                placeholder="New Password"
                                variant="filled"
                                type="password"
                                fullWidth
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div className={clsx("form-item", classes.division)}>
                            <TextField
                                // error={errorText.length === 0 ? false : true}
                                // helperText={errorText}
                                // label="Confirm Password"
                                placeholder="Confirm Password"
                                variant="filled"
                                name="confirmPassword"
                                type="password"
                                fullWidth
                                value={confirmPassword}
                                // onChange={checkConfirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="button-container">
                        <LoaderButton
                            type="submit"
                            className={"btn submit-btn-item"}
                            variant="contained"
                            loading={loading}
                        >
                            {"Reset Password"}
                        </LoaderButton>
                    </div>
                </form>

            </ForgotPasswordBox>
            <OurPartnerBox >
                <h1>Our Partners</h1>
                <Flickity className={'carousel'}
                    elementType={'div'}
                    options={flickityOptions}
                    disableImagesLoaded={false}
                    reloadOnUpdate
                    static
                >
                    <div class="carousel-cell carousel-cell-1">
                        <img class="carousel-cell-image" src={Partner1} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-2">
                        <img class="carousel-cell-image" src={Partner2} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-3">
                        <img class="carousel-cell-image" src={Partner3} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-4">
                        <img class="carousel-cell-image" src={Partner4} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-5">
                        <img class="carousel-cell-image" src={Partner5} alt="partner 1" />
                    </div>
                    <div class="carousel-cell carousel-cell-6">
                        <img class="carousel-cell-image" src={Partner6} alt="partner 1" />
                    </div>
                </Flickity>
                <img src={Hexagones} className='hexagons-img' alt="" />
                <Typography align='center' variant="subtitle2" style={{ marginTop: "30px", color: "white" }}>
                    Copyright © 2021 by Gortt
                </Typography>
            </OurPartnerBox>
        </ForgotPasswordWrapper>
    )
}

export default ResetPassword;
