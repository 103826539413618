import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { store } from "redux/store";
import { showToast } from "redux/General/Actions";
import Flickity from "react-flickity-component";
import "./flickity.css";
// DESIGN
import { Button, TextField, Typography, Checkbox, FormGroup, FormControlLabel, InputAdornment, IconButton } from "@material-ui/core";

// STYLES
import { LoginWrapper, LoginBox, useStyles, OurPartnerBox } from "./Login.style";
import LoaderButton from '../../Commons/Loader/LoaderButton';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import QuestionDialog from "./QuestionDialog";
import PasswordDialog from "./PasswordDialog";
import { MODAL_TYPES } from "Helpers/Constants";
// CONSTANTS
import { URL_DASHBOARD } from "Helpers/Paths";
import Api from "Helpers/ApiHandler";

// IMAGE
import NCRLogo from "../../../Assets/ncrLogo.png";
import GovttLogo from "../../../Assets/gov.png";
import Partner1 from '../../../Assets/logo1 1.png';
import Partner2 from '../../../Assets/logo3 1.png';
import Partner3 from '../../../Assets/logo6 1.png';
import Partner4 from '../../../Assets/logo2 1.png';
import Partner5 from '../../../Assets/logo5 1.png';
import Partner6 from '../../../Assets/logo4 1.png';
import Partner7 from '../../../Assets/logos/GenderAndChildAffairs.jpg';
import Partner8 from '../../../Assets/logos/MDT.png';
import Partner9 from '../../../Assets/logos/MNS.jpg';
import Partner10 from '../../../Assets/logos/MOH1.jfif';
import Partner11 from '../../../Assets/logos/MOL.jfif';
import Partner12 from '../../../Assets/logos/MOP.jfif';
import Partner13 from '../../../Assets/logos/MPA.jfif';
import Partner14 from '../../../Assets/logos/MSCD.png';
import Partner15 from '../../../Assets/logos/MSDFS.jpg';
import Partner16 from '../../../Assets/logos/MYDNS.png';
import Hexagones from '../../../Assets/Hexagones.png'

// REDUX
import { loginUser } from "redux/auth/actions";
import PromotionDialog from "./PromotionDialog";
import BackupDialog from "./BackupDialog";

var CryptoJS = require("crypto-js");

function Login(props) {
    let [loading, setLoading] = useState(false),
        [errorText, setErrorText] = useState('');
    const [modal, setModal] = useState({
        visible: false,
        data: {},
        type: MODAL_TYPES.VIEW
    })
    const [passwordModal, setPasswordModal] = useState({
        visible: false,
        data: {},
        type: MODAL_TYPES.VIEW
    })
    const [promotionBox, setPromotionBox] = useState({
        visible: false,
        data: {},
        type: MODAL_TYPES.VIEW
    })
    const [backupBox, setBackupBox] = useState({
        visible: false,
        data: {},
        type: MODAL_TYPES.VIEW
    })
    const [scfValues, setScfValues] = useState(null)
    const [level, setLevel] = useState(null)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handlePromotionClose = () => {
        setPromotionBox(false);
    };
    
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [state, setState] = React.useState({
        checkedA: true,
    });
    const dispatch = useDispatch();
    const classes = useStyles();
    const date = new Date();
    let month = date.getMonth() + 1;
    let socketInstance = useSelector((state) => state?.Auth?.socket);

    useEffect(() => {
        checkIfAlreadyLoggedIn();
        let userInfo = getCookie("userSessionToken");
        if (userInfo != "") {
            var bytes = CryptoJS.AES.decrypt(userInfo, 'my-secret-key@123');
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            setEmail(decryptedData[0].email);
            setPassword(decryptedData[1].password);
        }
        // eslint-disable-next-line
    }, [])


    function toggleModal(type, data = {}) {
        localStorage.removeItem("counting");
        setModal({
            ...modal,
            type,
            data,
            visible: !modal.visible,
        })
    }

    function togglePromtionModal(type, data={}) {
        localStorage.removeItem("counting");
        setPromotionBox({
            ...promotionBox,
            type,
            data,
            visible: !promotionBox.visible,
        })
    }
    function toggleBackupBox(type, data={}) {
        localStorage.removeItem("counting");
        setBackupBox({
            ...backupBox,
            type,
            data,
            visible: !backupBox.visible,
        })
    }
    function togglePasswordModal(type, data = {}) {
        localStorage.removeItem("counting");
        setPasswordModal({
            ...passwordModal,
            type,
            data : data,
            visible: !passwordModal.visible,
        })
    }

    function onSuccess(type, data) {
        
        toggleModal();
        // getSubAdminList();
    }

    function onPasswordSuccess(type, data) {
        togglePasswordModal();
        toggleModal();
    }
    function onPromotionSuccess(type, data) {
        // console.log("in success ");
        // console.log("promition bnox-->", promotionBox);
        dispatch(loginUser({ isLoggedIn: "true", userName: promotionBox?.data?.userName, accessToken: promotionBox?.data?.accessToken, adminId: promotionBox?.data?.adminId, access_level: promotionBox?.data?.access_level}))
        props.history.push(URL_DASHBOARD);
        // togglePromtionModal()
        
    }
    function onBackupSuccess(type, data) {
        // console.log("in success ");
        // console.log("promition bnox-->", promotionBox);
        dispatch(loginUser({ isLoggedIn: "true", userName: backupBox?.data?.userName, accessToken: backupBox?.data?.accessToken, adminId: backupBox?.data?.adminId, access_level: backupBox?.data?.access_level}))
        props.history.push(URL_DASHBOARD);
        // togglePromtionModal()
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var details = {
            'email': email,
            'password': password,
        };

        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        try {
            setLoading(true);
            let response;
            response = await new Api().post("/admin/sign-in", {
                data: formBody,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                returnUnhandledError: true,
                returnError: true
            })
            console.log("monthh-->", month, "pmonth-->", response?.data?.scfValues?.pmonth);
            if (response.data.adminDetails.firstTimeLogin === 1) {
                dispatch(loginUser({ adminId: response.data.adminDetails.id }))
                if(response.data.adminDetails.firstTimePasswordChange === '1') {
                    toggleModal(MODAL_TYPES.ADD);
                } else {
                    togglePasswordModal(MODAL_TYPES.ADD, response.data.adminDetails)
                }
            } else  if(response.data?.adminDetails["admin_access_levels.name"] === "Level 1" && month === parseInt(response?.data?.scfValues?.pmonth)) {
                setLevel("Level 1")
                setScfValues(response.data.scfValues)
                togglePromtionModal(MODAL_TYPES.ADD, { isLoggedIn: "true", userName: response.data?.adminDetails?.first_name && response.data?.adminDetails?.first_name + " " + response.data?.adminDetails?.last_name, accessToken: response.data.authToken, pmonth : response?.data?.scfValues?.pmonth, adminId: response.data.adminDetails.id, access_level: response.data?.adminDetails["admin_access_levels.name"] ? response.data?.adminDetails["admin_access_levels.name"] : response.data?.adminDetails?.type })
               
              
                
                // console.log("in level if");
            }  else if(response.data?.adminDetails["admin_access_levels.name"] === "Level 11") {
                setLevel("Level 11");
                toggleBackupBox(MODAL_TYPES.ADD, { isLoggedIn: "true", userName: response.data?.adminDetails?.first_name && response.data?.adminDetails?.first_name + " " + response.data?.adminDetails?.last_name, accessToken: response.data.authToken, pmonth : response?.data?.scfValues?.pmonth, adminId: response.data.adminDetails.id, access_level: response.data?.adminDetails["admin_access_levels.name"] ? response.data?.adminDetails["admin_access_levels.name"] : response.data?.adminDetails?.type })
            } 
            else {
                    
                dispatch(loginUser({ isLoggedIn: "true", userName: response.data?.adminDetails?.first_name && response.data?.adminDetails?.first_name + " " + response.data?.adminDetails?.last_name, accessToken: response.data.authToken, adminId: response.data.adminDetails.id, access_level: response.data?.adminDetails["admin_access_levels.name"] ? response.data?.adminDetails["admin_access_levels.name"] : response.data?.adminDetails?.type }))
                // if(response?.data?.adminDetails !== "super"){
                //     if (socketInstance && response.data?.adminDetails?.id){
                //         socketInstance.emit('login', response.data?.adminDetails?.id)
                //     }
                // }
                props.history.push(URL_DASHBOARD);
            }
            setLoading(false);
            if (state.checkedA) {
                let data = [{ email: email }, { password: password }];
                // Encrypt
                var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'my-secret-key@123').toString();
                document.cookie = `userSessionToken=${ciphertext}`;
            } else {
                document.cookie = "userSessionToken=;max-age=0";
            }
        }
        catch (error) {
            store.dispatch(showToast(error.message && error.message, null));
            setLoading(false);
            console.log(
                "TCL ~ file: Drawer.jsx ~ line 25 ~ formSubmit ~ error",
                error
            );
        }
    };

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function handleCheckboxChange(event) {
        setState({ ...state, [event.target.name]: event.target.checked });
    }

    function checkIfAlreadyLoggedIn() {
        if (props.isLoggedIn)
            props.history.push(URL_DASHBOARD)
    }

    const flickityOptions = {
        pageDots: true,
        fade: true,
        imagesLoaded: true,
        groupCells: true,
        wrapAround: true,
        autoPlay: 4000,
    }

    return (
        <LoginWrapper className="flexs">
            <LoginBox className="box">
                <div className="logoWrapper">
                    <Link to="/dashboard">
                        <img src={NCRLogo} alt="LOGO" />
                    </Link>
                    <img src={GovttLogo} alt="LOGO" />
                </div>
                <Typography className={classes.heading} align='left' variant="h6" gutterBottom>
                    <b>Sign In</b>
                </Typography>
                <Typography className={classes.subHeading} align='left' variant="subtitle2" >
                    Welcome back! Sign in with your Email and Password
                </Typography>
                <form className={classes.root} onSubmit={handleSubmit} noValidate>
                    <div className="form-container">
                        <div className={clsx("form-item", classes.division)}>
                            <TextField
                                name="email"
                                variant="filled"
                                type="email"
                                fullWidth
                                placeholder="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-item" style={{ marginRight: "0px" }}>
                            <TextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="Password"
                                variant="filled"
                                type={showPassword ? "text" : "password"}
                                fullWidth
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                           
                        </div>
                    </div>
                    <div className="form-forgot">
                        <div className="remember">
                        <FormGroup row >
                                <FormControlLabel
                                    className={classes.rememberButton}
                                    control={<Checkbox color={'default'} checked={state.checkedA} onChange={handleCheckboxChange} name="checkedA" />}
                                    label="Remember me."
                                />
                            </FormGroup>
                            </div>
                            <div className="forgot">
                            <Typography align='left' className={classes.forgotButton}>
                                <Link to="/forgot-password" className={"forgot-password"}>Forgot password?</Link>
                            </Typography>
                            </div>
                    </div>
                    <div className="button-container">
                        <LoaderButton
                            type="submit"
                            // width={"540px"}
                            width="100%"
                            className={"btn submit-btn-item"}
                            variant="contained"
                            loading={loading}
                        >
                            Login
                        </LoaderButton>

                        {/* <div className="terms-and-disclaimer">
                            <p>
                                <strong >
                                    <a href="#" onClick={handleClickOpen}>
                                        Terms of services
                                    </a>
                                </strong>
                                {" "}
                                and
                                {" "} 
                                <strong>
                                    <a href="#" onClick={handleClickOpen}>
                                        Legal Disclaimer
                                    </a>
                                </strong>
                            </p>
                        </div> */}

                    </div>
                    {promotionBox.visible && (
                        <PromotionDialog
                            type={promotionBox.type}
                            data={promotionBox.data}
                            open={promotionBox.visible}
                            title={"Promotion Alert"}
                            onSuccess={onPromotionSuccess}
                            onClose={togglePromtionModal} />
                    )}
                    {backupBox.visible && (
                        <BackupDialog
                            type={backupBox.type}
                            data={backupBox.data}
                            open={backupBox.visible}
                            title={"Backup Alert"}
                            onSuccess={onBackupSuccess}
                            onClose={toggleBackupBox} />
                    )}
                    {
                        passwordModal.visible && (
                        <PasswordDialog
                                type={passwordModal.type}
                                data={passwordModal.data}
                                open={passwordModal.visible}
                                title={"Change Your Password"}
                                submitText={"Update"}
                                onSuccess={onPasswordSuccess}
                                onClose={togglePasswordModal}
                            />
                        )
                    }
                            
                    {modal.visible && (
                        <QuestionDialog
                            type={modal.type}
                            data={modal.data}
                            open={modal.visible}
                            title={"Add Security Questions"}
                            submitText={"Add"}
                            onSuccess={onSuccess}
                            onClose={toggleModal} />
                    )}
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"Terms & Services"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Terms of services and Legal Disclaimer
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className={classes.buttons} autoFocus onClick={handleClose}>
                                Continue
                            </Button>
                            <Button className={classes.btnItem} onClick={handleClose} autoFocus>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </LoginBox>
            <OurPartnerBox>
                <h1>Our Partners</h1>
                <Flickity className={'carousel'}
                    elementType={'div'}
                    options={flickityOptions}
                    disableImagesLoaded={false}
                    reloadOnUpdate
                    static
                >
                    <div className="carousel-cell carousel-cell-1">
                        <img className="carousel-cell-image" src={Partner1} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-2">
                        <img className="carousel-cell-image" src={Partner2} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-3">
                        <img className="carousel-cell-image" src={Partner3} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-4">
                        <img className="carousel-cell-image" src={Partner4} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-5">
                        <img className="carousel-cell-image" src={Partner5} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-6">
                        <img className="carousel-cell-image" src={Partner6} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-1">
                        <img className="carousel-cell-image" src={Partner7} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-2">
                        <img className="carousel-cell-image" src={Partner8} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-3">
                        <img className="carousel-cell-image" src={Partner9} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-4">
                        <img className="carousel-cell-image" src={Partner10} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-5">
                        <img className="carousel-cell-image" style={{width : "242",height : "208"}} src={Partner11} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-6">
                        <img className="carousel-cell-image" src={Partner12} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-1">
                        <img className="carousel-cell-image" src={Partner13} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-4">
                        <img className="carousel-cell-image" src={Partner14} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-5">
                        <img className="carousel-cell-image" src={Partner15} alt="partner 1" />
                    </div>
                    <div className="carousel-cell carousel-cell-6">
                        <img className="carousel-cell-image" src={Partner16} alt="partner 1" />
                    </div>

                </Flickity>
                <img src={Hexagones} className='hexagons-img' alt="" />
                <Typography align='center' variant="subtitle2" style={{ marginTop: "30px", color: "white" }}>
                    Copyright © 2021 by Gortt
                </Typography>
            </OurPartnerBox>
        </LoginWrapper >
    )
}

const mapReduxStateToProps = (state) => ({
    isLoggedIn: state.Auth.isLoggedIn,
})

export default connect(mapReduxStateToProps, {
    loginUser
})(withRouter(Login));