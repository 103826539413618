// CORE
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { connect, useDispatch, useSelector } from 'react-redux';

import Toolbar from '@material-ui/core/Toolbar';
import { store } from "redux/store";
import { showToast } from "redux/General/Actions";
// USER DEFINED
import Routes from "Routes/Route";
import EventManager from "./EventManager/EventManager";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ContentWrapper } from "./Website.style";
import Sidebars from './Commons/Sidebars/Sidebars';
import Topbars from './Commons/Topbars/Topbars';
import Footers from './Commons/Footer/Footer';
import { IdleTimer } from '../Helpers/Utils';
import Api from "Helpers/ApiHandler";
import { socketInstance } from 'redux/auth/actions';
import { connectSocket, socketTest } from 'socket';


function Website(props) {
	const [isTimeout, setIsTimeout] = useState(false);
	const [timerVal, setTimerVal] = useState(null)
	const dispatch = useDispatch();
    let socket = useSelector((state) => state?.Auth?.socket);

	const useStyles = makeStyles((theme) => ({
		content: {
			backgroundColor: props.isLoggedIn && "#F5F6FA",
			padding: props.isLoggedIn && "15px",
			[theme.breakpoints.down('xs')]: {
				padding: props.isLoggedIn && "15px 15px 15px 75px",
				overflow : "hidden",
			  },
		},
	}));
	const classes = useStyles();

	useEffect(() => {
		fetchWebsiteTimeout()
		if (props.isLoggedIn) {
			document.body.style = 'background-color: #F5F6FA;';
			const timer = new IdleTimer({
				timeout: timerVal && timerVal,
				onTimeout: () => {
					setIsTimeout(true);
				},
				onExpired: () => {
					setIsTimeout(true);
				}
			});

			return () => {
				timer.cleanUp();
			};
		}
		else {
			document.body.style = !props.isLoggedIn && 'background-color: #FFFFFF;';
		}
	}, [props.isLoggedIn])
	
	async function fetchWebsiteTimeout() {
		try {
			const response = await new Api().get("/admin/scfparameter/view/4", {
				returnUnhandledError: true,
				returnError: true
			})
			const timerValue = response?.data?.value ? parseInt(response?.data?.value) : 30;
			// console.log("timerValues--->", timerValue * 60);
			setTimerVal(timerValue*60)
			// console.log("scf view parameter resoponse ->", response);
		} catch (error) {
			store.dispatch(showToast(error.message && error.message, null));
			console.log(
				"website timeout fetch error",
				error
			);
		}
	}

	useEffect(() => {
		if (!props.isLoggedIn) return;
	
		connectSocket();
	
		if (!socketTest) {
		  return;
		}
	
		dispatch(socketInstance(socketTest));
	
		socketTest.open();

		socketTest.on("connect", () => {
		  console.log("Socket Connected");
		});
		
		return () => {
			socketTest && socketTest.close();
		  dispatch(socketInstance(null));
		};
	  }, [props.isLoggedIn, dispatch]);
	
	return (
		<React.Fragment>
			{
				props.isLoggedIn &&
				<>
					<Sidebars />
				</>
			}
			<ContentWrapper className={clsx({
				[classes.content]: props.isLoggedIn
			})}>
				{props.isLoggedIn &&
					<Topbars />
				}
				<Routes />
				{props.isLoggedIn && <Footers />}
			</ContentWrapper>

			<EventManager />
		</React.Fragment>
	)
}


const mapReduxStateToProps = (state) => ({
	isLoggedIn: state.Auth.isLoggedIn,
})

export default connect(mapReduxStateToProps)(Website);
