    import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PrintIcon from '@mui/icons-material/Print';
import { showToast } from "redux/General/Actions";
import exportFromJSON from 'export-from-json';
import { useDispatch } from 'react-redux';
import printJS from "print-js";
import { utcLocalDateTime } from 'Helpers';
import { SnackbarWrapper, SnackbarContentWrapper } from "./Toast.style";

// IMAGES
import CloseIcon from '@material-ui/icons/Close';
function Toast(props) {
    let currentdate = utcLocalDateTime(new Date(), "YYYY-MM-DD")
    let currenttime = utcLocalDateTime(new Date(), "HH:mm:ss")
    let [loading, setLoading] = useState(false),
        [dataArray, setDataArray] = useState([]),
        [template, setTemplate] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        setDataArray([]);
        if (props.showIcon) {
            const exportData = props.data?.map((elm) => {
                return {
                  "First Name" : elm.firstName ? elm.firstName : "N/A",
                  "Last Name" : elm.lastName ? elm.lastName : "N/A",
                  "dob" : elm.dob ? elm.dob : "N/A",
                  "birthPin" : elm?.birthPin ? elm?.birthPin : "N/A",
                  "deletedOn" : new Date().toISOString().substring(0, 10)
                };
            });
                setDataArray(exportData)
                const reportTemplate = `
                <div class="container">
                <h2>Records Deleted from Master file</h2>
                <div class="result">
                    <h6>Date & Time -  ${currentdate} & ${currenttime}</h6><h6>Children's Report</h6><h6>Source - Master Records</h6>
                </div>
                <hr />
                <table>
                ${props?.data?.length > 0 ?
                        ` <thead>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>DOB</th>
                        <th>birthpin</th>
                        <th>Class</th>
                        <th>deletedOn</th>
                        <th>School</th>
                        
                    </thead>` : ""}
                   
                    <tbody>
                    ${props?.data?.length > 0 ? props?.data?.map((e) =>
                            `<tr>
                            <td class="header1">${e.firstName}</td>
                            <td class="header1">${e.lastName}</td>
                            <td class="header1">${e.dob}</td>
                            <td class="header1">${e.birthPin}</td>
                            <td class="headers">${e.children_school.currentClass}</td>
                            <td class="header1">${currentdate}</td>
                            <td class="headers">${e.children_school.school_institution.name}</td>
                                        </tr>`
                        ).join("") : `<tr>No Data</tr>`
                    }
                   
                    </tbody>
                </table>
                <hr />
                <div class="result">
                    <h6>Total Count : ${props?.data?.length}</h6>
                </div>
            </div>
                `
                setTemplate(reportTemplate)
        }
    }, [props.data]);

    const reportTemplate = `
    <div class="container">
    <h2>Records Tagged for Deletion</h2>
    <div class="result">
        <h6>Date & Time -  ${currentdate} & ${currenttime}</h6><h6>Children's Report</h6><h6>Source - Master Records</h6>
    </div>
    <hr />
    <table>
    ${props?.data?.length > 0 ?
            ` <thead>
            <th>First Name</th>
            <th>Last Name</th>
            <th>DOB</th>
            <th>birthpin</th>
            <th>Class</th>
            <th>deletedOn</th>
            <th>School</th>
            
        </thead>` : ""}
       
        <tbody>
        ${props?.data?.length > 0 ? props?.data?.map((e) =>
                `<tr>
                <td class="header1">${e.firstName}</td>
                <td class="header1">${e.lastName}</td>
                <td class="header1">${e.dob}</td>
                <td class="header1">${e.birthPin}</td>
                <td class="headers">${e.children_school.currentClass}</td>
                <td class="header1">${currentdate}</td>
                <td class="headers">${e.children_school.school_institution.name}</td>
                            </tr>`
            ).join("") : `<tr>No Data</tr>`
        }
       
        </tbody>
    </table>
    <hr />
    <div class="result">
        <h6>Total Count : ${props?.data?.length}</h6>
    </div>
</div>
    `

    async function onSaveExportData() {
        try {
            setLoading(true);
            let fileName = "process_master_list",
                exportType = "csv";
            exportFromJSON({ data: dataArray, fileName, exportType });
            setLoading(false);
            props.handleClose();
        } catch (error) {
            dispatch(showToast(error.message && error.message, null));
            setLoading(false);
            props.handleClose();
            console.log("error:", error);
        }
    }

 

    return (
        <SnackbarWrapper
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            autoHideDuration={props.duration === null ? null : props.duration || 3000}
            open={props.open}
            onClose={props.handleClose}
        >
            <SnackbarContentWrapper
                className={"themed"}
                aria-describedby="client-snackbar"
                classes={{
                    message: "notification-message",
                }}
                message={
                    <React.Fragment>
                        {props.message}
                    </React.Fragment>
                }
                action={props.showIcon ? [
                    <Tooltip title="Export Processed Record" placement="top">
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className="close-icon"
                            onClick={onSaveExportData}
                        >
                            <SaveAltIcon />
                        </IconButton>
                    </Tooltip>,
                    <Tooltip title="Print Processed Record" placement="top">
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className="close-icon"
                            onClick={() => printJS({
                                type: "raw-html",
                                printable: reportTemplate,
                                style: "hr {margin-right:10px} th {padding-right:10px;} td {padding-right:10px;} .container {border: 5px solid black;padding: 20px 0px 20px 80px; width:80%} .result {display: flex; justify-content: space-between; width:80%; } h6 {margin-bottom:0.5;margin-top:0;}"
                            })}
                        >
                            <PrintIcon />
                        </IconButton>
                    </Tooltip>
                    ,
                    <Tooltip title="Close" placement="top">
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className="close-icon"
                            onClick={props.handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>,
                ] : [<IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className="close-icon"
                    onClick={props.handleClose}
                >
                    <CloseIcon />
                </IconButton>,
                ]}
            />
        </SnackbarWrapper>
    )
}

export default Toast;