import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useStyles } from './BasicTabs.style';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }} style={{ padding: "24px 0px 0px 76px" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    let { tabLabel, children } = props.data;
    // let { children, ...rest } = props;
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ marginTop: "30px", flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 230 }}
        >
            <Tabs
                centered
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                // borderRight: 1,
                sx={{ borderColor: 'divider' }}
                className={classes.container}
            >
                {
                    tabLabel.map((element, index) => {
                        return <Tab className={classes.tabButton} label={element} {...a11yProps(index)} />
                    })
                }
            </Tabs>
            {children.map((element, index) => {
                return <TabPanel value={value} index={index}>
                    {element}
                </TabPanel>
            })}
        </Box>
    );
}
