// CORE
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Toast from 'Components/Commons/Toast/Toast'

import { hideToast } from "redux/General/Actions";

class EventManager extends Component {

    render() {
        return (
            <React.Fragment>
                <Toast
                    open={this.props.toast.enable}
                    message={this.props.toast.message}
                    handleClose={this.props.hideToast}
                    duration={this.props.toast.duration}
                    showIcon={this.props.toast.showIcon}
                    data={this.props.toast.data}
                />
            </React.Fragment>
        );
    }
}

const mapReduxStateToProps = state => {
    return {
        toast: state.General.toast,
    }
}

export default connect(mapReduxStateToProps, {
    hideToast
})(EventManager);
