import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        "& .MuiTabs-flexContainer": {
            height: "200px"
        }
    },
    tabButton: {
        color: "#D85767 !important",
        "& .MuiButtonBase-root-MuiTab-root": {
            color: "#D85767"
        }
    }
}));