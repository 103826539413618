export const URL_DASHBOARD = "/dashboard";
export const URL_ADMIN_USER = "/admins/sub-menu/users";
export const URL_ADMIN = "/admins";
export const URL_ADMIN_SUBMENU = "/admins/sub-menu";
export const URL_ADMIN_EXPORT_SUBMENU = "/admins/sub-menu/export";
export const URL_ADMIN_EXPORT_SCF = "/admins/sub-menu/exportscf";
export const URL_UPLOAD_SUBMENU = "/admins/sub-menu/upload-submenu";
export const URL_COLLAB_WITH_USERS = "/collab-with-users";
export const URL_ADMIN_EXPORT_DATAENTRY = "/admins/sub-menu/export-data-entry";
export const URL_LOGIN = "/login";
export const URL_OTP = "/forgot-password/otp";
export const URL_RESET_PASSWORD = "/forgot-password/reset-password";
export const URL_STAFF_MEMBERS = "/staff-members";
export const URL_REPORTS = "/reports";
export const URL_EXPORT_REPORTS = "/admins/sub-menu/report"
export const URL_PAGES = "/pages";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_HELP = "/help";
export const URL_ALERTS = "/alert";
export const URL_INCIDENTS = "/incidents";
export const URL_TASKS = "/tasks";
export const URL_REQUEST_ACCESS = "/request-access";
export const URL_ADMIN_DATA_ENTRY = "/admins/sub-menu/data-entry";
export const URL_ADD_CHILDREN = "/child-management/add";
export const URL_CHILD_MANAGEMENTS = "/child-management";
export const URL_CHILD_MANAGEMENTS_RESTRICTED = "/restricted-child-management";
export const URL_CHILD_SUBMENU = "/child-management/sub-menu";
export const URL_DATA_ENTRY = "/data-entry";
export const URL_POPULATE_DATABASE_EDUCATION = "/admins/populate-database-education";
export const URL_POPULATE_DATABASE_HEALTH = "/admins/populate-database-health";
export const URL_UPLOAD_SPORTS = "/admins/upload_sports";
export const URL_HOSPITAL_LIST = "/admins/system-control-files/hospital-list";
export const URL_SCHOOL_LIST = "/admins/system-control-files/school-list";
export const URL_SEARCH_DATABASE = "/child-management/search-database";
export const URL_SEARCH_DATABASE_RESTRICTED = "/child-management/restricted-search-database";
export const URL_SEARCH_DATABASE_EDUCATION = "/child-management/search-database/education-record";
export const URL_SEARCH_DATABASE_HEALTH = "/child-management/search-database/health-record";
export const URL_SEARCH_DATABASE_IMMIGRATION = "/child-management/search-database/immigration";
export const URL_SEARCH_DATABASE_TRINIDAD_SOCIAL = "/child-management/search-database/trinidad-social";
export const URL_SEARCH_DATABASE_TOBAGO_SOCIAL = "/child-management/search-database/tobago-social";
export const URL_SEARCH_DATABASE_CATT = "/child-management/search-database/catt";
export const URL_SEARCH_DATABASE_CATT_VIEW = "/child-management/search-database/catt/view"
export const URL_SEARCH_DATABASE_CTU = "/child-management/search-database/ctu";
export const URL_SEARCH_DATABASE_CTU_VIEW = "/child-management/search-database/ctu/view"
export const URL_SEARCH_DATABASE_LABOR = "/child-management/search-database/labor";
export const URL_SEARCH_DATABASE_LABOR_VIEW = "/child-management/search-database/labor/view"
export const URL_SEARCH_DATABASE_SPORTS = "/child-management/search-database/sports";
export const URL_SEARCH_DATABASE_SPORTS_VIEW = "/child-management/search-database/sports/view"
export const URL_SEARCH_DATABASE_GLOBAL_SEARCH = "/child-management/search-database/global-search";
export const URL_SEARCH_DATABASE_YTRC = "/child-management/search-database/ytrc";
export const URL_SEARCH_DATABASE_EDUCATION_VIEW = "/child-management/search-database/education-record/view";
export const URL_SEARCH_DATABASE_HEALTH_VIEW = "/child-management/search-database/health-record/view";
export const URL_SEARCH_DATABASE_IMMIGRATION_VIEW = "/child-management/search-database/immigration/view";
export const URL_SEARCH_DATABASE_TRINIDAD_SOCIAL_VIEW = "/child-management/search-database/trinidad-social/view";
export const URL_SEARCH_DATABASE_TOBAGO_SOCIAL_VIEW = "/child-management/search-database/tobago-social/view"
export const URL_SEARCH_DATABASE_YTRC_VIEW = "/child-management/search-database/ytrc/view";
export const URL_ALERT_USER_LIST = "/admins/system-control-files/alert-user";
export const URL_ALERT_USER_EMAIL = "/admins/system-control-files/alert-user/email";
export const URL_MANAGE_ALERTS = "/child-management/manage-alert";
export const URL_MANAGE_ALERTS_VIEWS = "/child-management/manage-alert/view-alert-details";
export const URL_MANAGE_ALERTS_VIEWS_MENU = "/alert/view-alert-details";
export const URL_MANAGE_INCIDENTS_VIEWS_MENU = "/incident/view-incident-details";
export const URL_INCIDENT_USER_LIST = "/admins/system-control-files/incident-user";
export const URL_INCIDENT_USER_EMAIL = "/admins/system-control-files/incident-user/email";
export const URL_INCIDENT_OPEN_INCIDENT = "/child-management/incident/open-incident";
export const URL_INCIDENT_MANAGE_INCIDENT = "/child-management/incident/manage-incident";
export const URL_MANAGE_RECORDS = "/child-management/recordsDeletion/manage-records";
export const URL_ADMIN_SCF_DROPDOWN_EDUCATION_TABLES = "/admins/system-control-files/education-tables";
export const URL_ADMIN_SCF_DROPDOWN_HEALTH_TABLES = "/admins/system-control-files/health-tables";
export const URL_ADMIN_SCF_DROPDOWN_INCIDENT_TABLES = "/admins/system-control-files/incident-tables";
export const URL_ADMIN_SCF_DROPDOWN_MINISTRY_TABLES = "/admins/system-control-files/ministry-groups-tables";
export const URL_ADMIN_SCF_DROPDOWN_COUNTRY_TABLES = "/admins/system-control-files/country-city-tables";
export const URL_ADMIN_SCF_DROPDOWN_CLASS_TABLES = "/admins/system-control-files/class-codes-tables";
export const URL_ADMIN_SCF_SECURITY_QUESTIONS = "/admins/system-control-files/security-questions";
export const URL_ADMIN_SCF_EMAIL_TEMPLATES = "/admins/system-control-files/email-template";
export const URL_TASKS_INCIDENT = "/tasks/incidents-tasks";
export const URL_TASKS_ALERT = "/tasks/alerts-tasks";
export const URL_ADMIN_LOGIN_USER = "/admins/users-online";
export const URL_SCF_PARAMETERS = "/admins/scf-parameters";
export const URL_SCF_VACCINES = "/admins/scf-vaccines";
export const URL_USER_LOGS = "/admins/user-logs";
export const URL_SECURITY_LOGS = "/admins/security-logs";