import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { showToast } from "redux/General/Actions";
import { withRouter } from 'react-router-dom';
import { IconButton, Typography, Button } from '@material-ui/core';
import Api from "Helpers/ApiHandler";
import Popover from '@mui/material/Popover';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { makeStyles } from '@material-ui/core/styles';
import avatarUser from 'Assets/avatarUser.png';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { logOutUser } from "redux/auth/actions";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { store } from "redux/store";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        width: "90%"
    },
    root: {
        flexGrow: 1,
        padding: "0px 15px 0px 15px"
    },
    cardRoot: {
        borderRadius: "12px",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: "flex"
    },
    nonPaper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    avatar: {
        margin: 'auto',
        backgroundColor: "#FFFF",
        borderRadius: "6px"
    },
    paperTool: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "12px",
    },
    mobile: {
        [theme.breakpoints.down('xs')]: {
            // visibility:"visible"
            display: "block !important"
          },
          [theme.breakpoints.up('sm')]: {
            // visibility:"hidden"
            display: "none !important"
          },
    },
    desktop:{
        [theme.breakpoints.down('xs')]: {
            // visibility:"hidden"
            display: "none !important"
          },
    },
    simplePaper: {
        padding: theme.spacing(2),
        display: "flex"
    },
    heading: {
        color: "#1C1B1B",
        [theme.breakpoints.down('xs')]: {
            // visibility:"visible"
            fontSize: "0.95rem !important",
            paddingTop : "12px"
          },
    },
    welcomHeading: {
        color: "#1C1B1B",
        fontSize: "0.9rem",
        padding: "3px 15px"
    },
    popover: {
        padding: "10px",
        backgroundColor: "white",
        "& 	.MuiTypography-h6": {
            fontSize: "16px",
            marginBottom: "10px"
        }
    },
    popoverAction: {
        display: "flex",
        justifyContent: "space-around"
    },
    btnItem: {
        backgroundColor: "#D85767",
        color: "#FFFFFF",
        height: "30px",
        "&:hover": {
            backgroundColor: "#D85767",
        }
    },
    buttons: {
        backgroundColor: "#C9C9C9 !important",
        color: '#1C1B1B !important',
        height: "30px",
        '&:hover': {
            backgroundColor: "#C9C9C9 !important",
            color: '#1C1B1B !important',
        },
        '& .MuiButton-contained .Mui-disabled': {
            backgroundColor: '#D85767',
        }
    },
}));



function Topbars(props) {
    const accessLevel = localStorage?.getItem('access_level')?.toLowerCase()?.replace(" ", "");
    let [loading, setLoading] = useState(false),
        [version, setVersion] = useState(null),
        [userName, setUserName] = useState("");
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ancEl, setAncEl] = React.useState(null);
    let socketInstance = useSelector((state) => state?.Auth?.socket);
    

    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openAncEl = Boolean(ancEl);
    // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //   setAnchorEl(event.currentTarget);
    // };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleLogoutClose  = () => {
        setAncEl(null);
    }

    useEffect(() => {
        let user = localStorage.getItem('userName') || "";
        setUserName(user);
        getVersionNumber();
    }, []);


    const getVersionNumber =  async () => {
        try {
            setLoading(true);
            let response = await new Api().get(
              `/${accessLevel}/scfparameters/view/15`, {
                returnUnhandledError: true,
                returnError: true
            });
           setVersion(response.data.value)
            setLoading(false);
        } catch (error) {
            store.dispatch(showToast(error.message && error.message, null));
            setLoading(false);
            console.log("error:", error);
        }
    }

    const handleLogout  = (event) => {
        setAncEl(event.currentTarget);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    async function onConfirm() {
      try {
        setLoading(true);
        await new Api().post("/admin/sign-out", {
          returnUnhandledError: true,
          returnError: true,
        });

        if (socketInstance) {
          const adminId = localStorage.getItem("adminId");

          if (adminId) {
            socketInstance.emit("logout", adminId);
          }
          socketInstance.emit("disconnected");
        }

        props.logOutUser();
        setLoading(false);
      } catch (error) {
        dispatch(showToast(error.message && error.message, null));
        setLoading(false);
        console.log(
          "TCL ~ file: Drawer.jsx ~ line 25 ~ formSubmit ~ error",
          error
        );
      }
    }

    // const open = Boolean(anchorEl);
    const id = openAncEl ? 'simple-popover' : undefined;

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paperTool}>
                        <div style={{display : "flex", flexDirection : "column"}}>
                            <Typography variant="h6" className={classes.heading}>
                                National Children's Registry 
                            </Typography>
                            <Typography style={{color : "red", alignSelf : "flex-start"}}>Vn: {version}</Typography>
                        </div>
                        <div className={classes.desktop} style={{ display: "flex", alignItems: "center" }}>
                            {userName &&
                                <Typography variant="h6" className={classes.welcomHeading}>
                                    Welcome, {userName}
                                </Typography>
                            }
                            {/* <SearchIcon /> */}
                            <NotificationsIcon style={{ margin: "0px 20px" }} />
                             <Avatar variant="square" aria-label="logo" className={classes.avatar} >
                                <IconButton className="icon" onClick={handleLogout}>
                                    <img src={avatarUser} alt="logo" />
                                </IconButton>
                            </Avatar>
                    </div>
                    <div className={classes.mobile} style={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar variant="square"  aria-label="logo" className={classes.avatar} ><img src={avatarUser} alt="logo" /></Avatar>
          </IconButton>
        </Tooltip>
        </div>
                        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
         {userName &&
                                <Typography variant="h6" className={classes.welcomHeading}>
                                    Welcome, {userName}
                                </Typography>
                            }
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <SearchIcon fontSize="small" />
          </ListItemIcon>
          Search
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            {/* <Settings fontSize="small" /> */}
            <NotificationsIcon fontSize="small" />
          </ListItemIcon>
          Notification
        </MenuItem>
        <MenuItem onClick={()=>onConfirm()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
                        <Popover
                            id={id}
                            open={openAncEl}
                            anchorEl={ancEl}
                            onClose={handleLogoutClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className={classes.popover}>

                                <Typography variant="h6" className={classes.heading}>
                                    <ErrorRoundedIcon color="warning" />Are you sure to logout?
                                </Typography>
                                <div className={classes.popoverAction}>
                                    <Button className={classes.buttons} autoFocus onClick={handleLogoutClose}>
                                        No
                                    </Button>
                                    <Button className={classes.btnItem} onClick={() => {
                                        handleLogoutClose();
                                        onConfirm();
                                    }} autoFocus>
                                        Yes
                                    </Button>
                                </div>
                            </div>
                        </Popover>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default connect(null, {
    logOutUser
})(withRouter(Topbars));