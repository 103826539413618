//react redux
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//material ui
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//utils
import { BasicDialog } from "Components/Commons/Commons";
import LoaderButton from "Components/Commons/Loader/LoaderButton";
import { loginUser } from "redux/auth/actions";
//styles
import { useStyles } from "./QuestionDialog.style";
function BackupDialog(props) {
  
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
 

  return (
    <div className={classes.container}>
      <BasicDialog open={open} onClose={props.onSuccess} {...props}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent className={classes.btnAction}>
        Remember to Backup BEFORE processing Data Entry transactions. and BEFORE running any option in POPULATE Db
        <DialogActions>
                  <LoaderButton
                    type="submit"
                    onConfirm={props.onSuccess}
                    onClick={props.onSuccess}
                    className={classes.btnItem}
                    variant="contained"
                    loading={loading}
                    width="120px"
                    height="40px"
                  >
                    CONTINUE
                  </LoaderButton>
                </DialogActions>
        </DialogContent>
      </BasicDialog>
    </div>
  );
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
});

export default connect(mapReduxStateToProps, {
  loginUser,
})(withRouter(BackupDialog));
