import React from "react";
import LazyLoader from '@loadable/component'

import {
    URL_DASHBOARD,
    URL_ADMIN_USER, URL_ADMIN,
    URL_CHILD_MANAGEMENTS,
    URL_OTP,
    URL_ADD_CHILDREN,
    URL_ADMIN_SUBMENU,
    URL_ADMIN_EXPORT_SUBMENU,
    URL_ADMIN_EXPORT_SCF,
    URL_COLLAB_WITH_USERS,
    URL_ADMIN_EXPORT_DATAENTRY,
    URL_PAGES,
    URL_REPORTS,
    URL_EXPORT_REPORTS,
    URL_FORGOT_PASSWORD,
    URL_ALERTS,
    URL_ADMIN_DATA_ENTRY,
    URL_HELP,
    URL_DATA_ENTRY,
    URL_INCIDENTS,
    URL_TASKS,
    URL_POPULATE_DATABASE_EDUCATION,
    URL_POPULATE_DATABASE_HEALTH,
    URL_HOSPITAL_LIST,
    URL_SCHOOL_LIST,
    URL_SEARCH_DATABASE,
    URL_SEARCH_DATABASE_EDUCATION,
    URL_SEARCH_DATABASE_HEALTH,
    URL_SEARCH_DATABASE_IMMIGRATION,
    URL_SEARCH_DATABASE_TRINIDAD_SOCIAL,
    URL_SEARCH_DATABASE_EDUCATION_VIEW,
    URL_SEARCH_DATABASE_HEALTH_VIEW,
    URL_SEARCH_DATABASE_IMMIGRATION_VIEW,
    URL_SEARCH_DATABASE_TRINIDAD_SOCIAL_VIEW,
    URL_SEARCH_DATABASE_TOBAGO_SOCIAL,
    URL_SEARCH_DATABASE_TOBAGO_SOCIAL_VIEW,
    URL_SEARCH_DATABASE_YTRC,
    URL_SEARCH_DATABASE_YTRC_VIEW,
    URL_ALERT_USER_LIST,
    URL_ALERT_USER_EMAIL,
    URL_MANAGE_ALERTS,
    URL_MANAGE_ALERTS_VIEWS,
    URL_MANAGE_ALERTS_VIEWS_MENU,
    URL_MANAGE_INCIDENTS_VIEWS_MENU,
    URL_INCIDENT_USER_LIST,
    URL_CHILD_SUBMENU,
    URL_INCIDENT_USER_EMAIL,
    URL_INCIDENT_OPEN_INCIDENT,
    URL_INCIDENT_MANAGE_INCIDENT,
    URL_ADMIN_SCF_DROPDOWN_EDUCATION_TABLES,
    URL_ADMIN_SCF_DROPDOWN_HEALTH_TABLES,
    URL_ADMIN_SCF_DROPDOWN_INCIDENT_TABLES,
    URL_ADMIN_SCF_DROPDOWN_MINISTRY_TABLES,
    URL_ADMIN_SCF_DROPDOWN_COUNTRY_TABLES,
    URL_ADMIN_SCF_DROPDOWN_CLASS_TABLES,
    URL_ADMIN_SCF_SECURITY_QUESTIONS,
    URL_ADMIN_SCF_EMAIL_TEMPLATES,
    URL_TASKS_INCIDENT,
    URL_TASKS_ALERT,
    URL_REQUEST_ACCESS,
    URL_ADMIN_LOGIN_USER,
    URL_UPLOAD_SUBMENU,
    URL_SCF_PARAMETERS,
    URL_SCF_VACCINES,
    URL_USER_LOGS,
    URL_SECURITY_LOGS,
    URL_SEARCH_DATABASE_GLOBAL_SEARCH,
    URL_MANAGE_RECORDS,
    URL_UPLOAD_SPORTS,
    URL_SEARCH_DATABASE_CATT,
    URL_SEARCH_DATABASE_CATT_VIEW,
    URL_SEARCH_DATABASE_CTU,
    URL_SEARCH_DATABASE_CTU_VIEW,
    URL_SEARCH_DATABASE_LABOR,
    URL_SEARCH_DATABASE_LABOR_VIEW,
    URL_SEARCH_DATABASE_SPORTS,
    URL_SEARCH_DATABASE_SPORTS_VIEW,
    URL_CHILD_MANAGEMENTS_RESTRICTED,
    URL_SEARCH_DATABASE_RESTRICTED,
} from "Helpers/Paths";

import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@material-ui/icons/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WebIcon from '@material-ui/icons/Web';
import UsersIcon from '@material-ui/icons/SupervisorAccount';
import AdminIcon from '@material-ui/icons/AssignmentInd';
import ContactsIcon from '@material-ui/icons/Contacts';
export default [
    {
        path: "/",
        exact: true,
        component: LazyLoader(() => import('Container/Dashboard/Dashboard')),
    }, 
    {
        path: URL_DASHBOARD,
        exact: true,
        component: LazyLoader(() => import('Container/Dashboard/Dashboard')),
        sidebar: {
            name: "Dashboard",
            icon: <DashboardIcon />
        }
    },
    // {
    //     path: URL_COLLAB_WITH_USERS,
    //     exact: true,
    //     component: LazyLoader(() => import('Container/CollabWithUsers/CollabWithUsers')),
    //     sidebar: {
    //         name: "Collaborate With Users",
    //         icon: <ContactsIcon />
    //     }
    // },
    {
        path: URL_CHILD_MANAGEMENTS,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/ChildManagement')),
        sidebar: {
            name: "Child Management",
            filterName : "Child Management",
            icon: <DescriptionIcon />
        }
    }, 
    {
        path: URL_CHILD_MANAGEMENTS_RESTRICTED,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagementRestricted/ChildManagement')),
        sidebar: {
            name: "Child Management",
            filterName : "Child Management Restricted",
            icon: <DescriptionIcon />
        }
    }, 
    {
        path: URL_TASKS,
        exact: true,
        component: LazyLoader(() => import('Container/TaskMenu/TaskMenu')),
        sidebar: {
            name: "Tasks",
            icon: <AssignmentIcon />
        }
    }, {
        path: URL_REPORTS,
        exact: true,
        component: LazyLoader(() => import('Container/ReportMenu/ReportMenu')),
        sidebar: {
            name: "Reports",
            icon: <AssessmentIcon />
        }
    },
    {
        path: URL_INCIDENTS,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/Incidents/ManageIncidents/ManageIncidents')),
        sidebar: {
            name: "Incidents",
            icon: <ApartmentIcon />
        }
    },
    {
        path: URL_ALERTS,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/ManageAlerts/ManageAlert')),
        sidebar: {
            name: "Alerts",
            icon: <ReportProblemIcon />
        }
    },
    {
        path: URL_HELP,
        exact: true,
        // component: LazyLoader(() => import('Container/Dashboard/Dashboard')),
        component: LazyLoader(() => import('Container/Help/Help')),
        sidebar: {
            name: "Help",
            icon: <HelpOutlineIcon />
        }
    },
    {
        path: URL_DATA_ENTRY,
        exact: true,
        component: LazyLoader(() => import('Container/DataEntry/DataEntry')),
        sidebar: {
            name: "Data Entry",
            icon: <BadgeIcon />
        }
    },
    // {
    //     path: URL_REQUEST_ACCESS,
    //     exact: true,
    //     component: LazyLoader(() => import('Container/RequestAccess/RequestAccess')),
    //     // component: LazyLoader(() => import('Container/Dashboard/Dashboard')),
    //     sidebar: {
    //         name: "Request Access",
    //         icon: <HelpOutlineIcon />
    //     }
    // },
    {
        path: URL_ADMIN,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/Administrator')),
        sidebar: {
            name: "Administrator",
            icon: <PersonIcon />
        }
    },
   
    {
        path: URL_SEARCH_DATABASE,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/SubMenu')),
    },
    {
        path: URL_SEARCH_DATABASE_RESTRICTED,
        exact: true,
        component: LazyLoader(() => import('Container//ChildManagementRestricted/SearchDatabase/SubMenu')),
    },
    {
        path: URL_SEARCH_DATABASE_EDUCATION,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/EducationRecord/EducationRecord')),
    },
    {
        path: URL_SEARCH_DATABASE_HEALTH,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/HealthRecord/HealthRecord')),
    },
    {
        path: URL_SEARCH_DATABASE_IMMIGRATION,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/Immigration/Immigration')),
    },
    {
        path: URL_SEARCH_DATABASE_TRINIDAD_SOCIAL,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/TrinidadSocialService/TrinidadSocialService')),
    },
    {
        path: URL_SEARCH_DATABASE_TOBAGO_SOCIAL,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/TobagoService/TobagoService')),
    },
    {
        path: URL_SEARCH_DATABASE_CATT,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/CATT/CATT')),
    },
    {
        path: URL_SEARCH_DATABASE_CATT_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/CATT/ViewCATT')),
    },
    {
        path: URL_SEARCH_DATABASE_CTU,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/CTU/CTU')),
    },
    {
        path: URL_SEARCH_DATABASE_CTU_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/CTU/ViewCTU')),
    },
    {
        path: URL_SEARCH_DATABASE_LABOR,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/Labor/Labor')),
    },
    {
        path: URL_SEARCH_DATABASE_LABOR_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/Labor/ViewLabor')),
    },
    {
        path: URL_SEARCH_DATABASE_SPORTS,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/Sports/Sports')),
    },
    {
        path: URL_SEARCH_DATABASE_SPORTS_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/Sports/ViewSports')),
    },
    {
        path: URL_SEARCH_DATABASE_GLOBAL_SEARCH,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/GlobalSearch/globalsearch')),
    },
    {
        path: URL_SEARCH_DATABASE_YTRC,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/YTRC/YTRC')),
    },
    {
        path: URL_POPULATE_DATABASE_EDUCATION,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/PopulateDatabase/PopulateDatabaseEducation/EducationData')),
    },
    {
        path: URL_POPULATE_DATABASE_HEALTH,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/PopulateDatabase/PopulateDatabaseHealth/HealthData')),
    },
    // {
    //     path: URL_UPLOAD_SPORTS,
    //     exact: true,
    //     component: LazyLoader(() => import('Container/Administrator/Upload/Sports')),
    // },
    {
        path: URL_HOSPITAL_LIST,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/HospitalList/HospitalList')),
    },
    {
        path: URL_EXPORT_REPORTS,
        exact: true,
        component: LazyLoader(() => import('Container/ReportMenu/ReportMenu')),
    },
    {
        path: URL_SCHOOL_LIST,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/SchoolList/SchoolList')),
    },
    {
        path: URL_ADMIN_USER,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/CreateNewUser/ApprovedCreateNewUser/User')),
    }, {
        path: URL_ADMIN_DATA_ENTRY,
        exact: true,
        component: LazyLoader(() => import('Container/DataEntry/DataEntry')),
    },
    {
        path: URL_ADMIN_SUBMENU,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/AdministratorSubMenu')),
    },
    {
        path: URL_ADMIN_EXPORT_SUBMENU,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/ExportMenu')),
    },
    {
        path: URL_ADMIN_EXPORT_SCF,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/ExportSCFSubMenu')),
    },
    {
        path: URL_UPLOAD_SUBMENU,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/UploadSubMenu')),
    },
    {
        path:URL_ADMIN_EXPORT_DATAENTRY,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/ExportSubMenu')),
    },
    {
        path: URL_CHILD_SUBMENU,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/ChildManagementSubMenu')),
    },
    {
        path: URL_SEARCH_DATABASE_EDUCATION_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/EducationRecord/ViewEducationRecord')),
    },
    {
        path: URL_SEARCH_DATABASE_HEALTH_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/HealthRecord/ViewHealthRecord')),
    },
    {
        path: URL_SEARCH_DATABASE_IMMIGRATION_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/Immigration/ViewImmigration')),
    },
    {
        path: URL_SEARCH_DATABASE_TRINIDAD_SOCIAL_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/TrinidadSocialService/ViewTrinidadSocialService')),
    },
    {
        path: URL_SEARCH_DATABASE_TOBAGO_SOCIAL_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/TobagoService/ViewTobagoService')),
    },
    {
        path: URL_SEARCH_DATABASE_YTRC_VIEW,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/SearchDatabase/YTRC/ViewYTRC')),
    },
    {
        path: URL_ALERT_USER_LIST,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/AlertUser/AlertUser')),
    },
    {
        path: URL_ALERT_USER_EMAIL,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/AlertUser/EmailEdit')),
    },
    {
        path: URL_MANAGE_ALERTS,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/ManageAlerts/ManageAlert')),
    },
    {
        path: URL_INCIDENT_USER_LIST,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/IncidentUser/IncidentUser')),
    },
    {
        path: URL_INCIDENT_USER_EMAIL,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/IncidentUser/EmailEdit')),
    },
    {
        path: URL_INCIDENT_OPEN_INCIDENT,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/Incidents/OpenIncidents/OpenIncidents')),
    },
    {
        path: URL_INCIDENT_MANAGE_INCIDENT,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/Incidents/ManageIncidents/ManageIncidents')),
    },
    {
        path: URL_MANAGE_RECORDS,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/RecordsDeletion/RecordsDeletion')),
    },
    {
        path: URL_ADMIN_SCF_DROPDOWN_EDUCATION_TABLES,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/DropDownTables/EducationDropDownTables/EducationDropDownTables')),
    },
    {
        path: URL_ADMIN_SCF_DROPDOWN_HEALTH_TABLES,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/DropDownTables/HealthDropDownTables/HealthDropDownTables')),
    },
    {
        path: URL_ADMIN_SCF_DROPDOWN_INCIDENT_TABLES,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/DropDownTables/IncidentDropDownTables/IncidentDropDownTables')),
    },
    {
        path: URL_ADMIN_SCF_DROPDOWN_MINISTRY_TABLES,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/DropDownTables/MinistryDropDownTables/MinistryDropDownTables')),
    },
    {
        path: URL_ADMIN_SCF_DROPDOWN_COUNTRY_TABLES,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/DropDownTables/CountryCityTables/CountryCityTables')),
    },
    {
        path: URL_ADMIN_SCF_DROPDOWN_CLASS_TABLES,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/DropDownTables/ClassCodesTables/ClassCodesTables')),
    },
    {
        path: URL_ADMIN_SCF_SECURITY_QUESTIONS,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/QuestionList/QuestionList')),
    },
    {
        path: URL_ADMIN_SCF_EMAIL_TEMPLATES,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/EmailTemplate/EmailTemplate')),
    },
    {
        path: URL_MANAGE_ALERTS_VIEWS,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/ManageAlerts/ViewAlert')),
    },
    {
        path: URL_MANAGE_ALERTS_VIEWS_MENU,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/ManageAlerts/ViewAlert')),
    },
    {
        path: URL_MANAGE_INCIDENTS_VIEWS_MENU,
        exact: true,
        component: LazyLoader(() => import('Container/ChildManagement/Incidents/ManageIncidents/ViewIncident')),
    },
    {
        path: URL_TASKS_INCIDENT,
        exact: true,
        component: LazyLoader(() => import('Container/TaskMenu/IncidentsTasks/IncidentsTasks')),
    },
    {
        path: URL_TASKS_ALERT,
        exact: true,
        component: LazyLoader(() => import('Container/TaskMenu/AlertsTasks/AlertsTasks')),
    },
    {
        path: URL_ADMIN_LOGIN_USER,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/UsersOnline/UsersOnline')),
    },
    {
        path: URL_SCF_PARAMETERS,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/SCFParameters/SCFParametersList')),
    },
    {
        path: URL_SCF_VACCINES,
        exact: true,
        component: LazyLoader(() => import('Components/SystemControlFiles/VaccineSCF/VaccineList')),
    },
    {
        path: URL_USER_LOGS,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/UserLogs/UserLogsList')),
    },
    {
        path: URL_SECURITY_LOGS,
        exact: true,
        component: LazyLoader(() => import('Container/Administrator/SecurityLogs/SecurityLogs')),
    },
]
