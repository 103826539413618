//react redux
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//material ui
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//utils
import { BasicDialog } from "Components/Commons/Commons";
import LoaderButton from "Components/Commons/Loader/LoaderButton";
import { InputWithLabel } from "Components/Commons/Commons";
import { loginUser } from "redux/auth/actions";
import Api from "Helpers/ApiHandler";

//styles
import { useStyles } from "./QuestionDialog.style";

function PasswordDialog(props) {
  const [inputs, setInputs] = useState([
    {
      label: "Old Password",
      name: "oldPassword",
      validation: { required: true },
      fieldType: "",
      type: "password",
    },
    {
      label: "New Password",
      name: "newPassword",
      validation: { required: true },
      fieldType: "",
      type: "password",
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      validation: { required: true },
      fieldType: "",
      type: "password",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  // const validationSchema = generateValidationSchema(inputs);
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is Required."),
    newPassword: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "New Password and Confirm Password must match"
    ),
  });

  const handleSubmit = async (values) => {
    // console.log("values in handle submit-->", values, props.data);
    let formBody = [];
    const details = {
      ...values,
      email: props?.data?.email,
    };
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");

    try {
      setLoading(true);
      const response = await new Api().post(`/admin/changepassword`, {
        data: formBody,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        returnUnhandledError: true,
        returnError: true,
      });
      // console.log("change password response-->", response);
      props.onSuccess(props.type, { ...props.data, ...details });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("change password first time login formSubmit error->", error);
    }
  };

  return (
    <div className={classes.container}>
      <BasicDialog open={open} onClose={props.onClose} {...props}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent className={classes.btnAction}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              getFieldProps,
            }) => (
              <Form className={classes.root}>
                {inputs.map((input) => (
                  <InputWithLabel
                    key={input.key}
                    className="classes.root"
                    name={input.name}
                    fieldType={input.fieldType}
                    placeholder={input.label}
                    label={input.label}
                    type={input.type}
                    value={values[input.name]}
                    onChange={handleChange}
                    setfieldvalue={setFieldValue}
                    getfieldprops={getFieldProps}
                    validated={touched[input.name] && errors[input.name]}
                  />
                ))}
                <DialogActions>
                  <Button className={classes.buttons} onClick={props.onClose}>
                    Cancel
                  </Button>
                  <LoaderButton
                    type="submit"
                    className={classes.btnItem}
                    variant="contained"
                    loading={loading}
                    width="120px"
                    height="40px"
                  >
                    {props.submitText}
                  </LoaderButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BasicDialog>
    </div>
  );
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
});

export default connect(mapReduxStateToProps, {
  loginUser,
})(withRouter(PasswordDialog));
