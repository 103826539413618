import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { BasicDialog } from "Components/Commons/Commons";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Api from "Helpers/ApiHandler";
import LoaderButton from 'Components/Commons/Loader/LoaderButton';
import { useStyles } from './QuestionDialog.style';
import { Formik, Form } from "formik";
import { generateValidationSchema } from "Helpers/Utils";
import { InputWithLabel } from "Components/Commons/Commons";
import { connect, useDispatch } from 'react-redux';
import { URL_DASHBOARD } from "Helpers/Paths";
import { loginUser } from "redux/auth/actions";
import { withRouter } from 'react-router-dom';

function QuestionDialog(props) {
    let [questionList, setQuestionList] = useState([]),
        [inputs, setInputs] = useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    const initialValues = {
        securityQuestion1: null,
        answer1: ""
    };

    const validationSchema = generateValidationSchema(inputs);
    let [loading, setLoading] = useState(false),
        [count, setCount] = useState([]);

    useEffect(() => {
        let mounted = true;
        // checkIfAlreadyLoggedIn();
        if (mounted)
            getQuestionList();
        return () => {
            mounted = false;
        }
    }, []);

    useEffect(() => {
        let mounted = true;
        // checkIfAlreadyLoggedIn();
        if (mounted) {
            setInputs([
                {
                    label: "Security Question 1",
                    name: "securityQuestion1",
                    validation: { required: true, object: true },
                    fieldType: { type: "AutoComplete", multiple: false, data: questionList }
                },
                {
                    label: "Answer",
                    name: "answer1",
                    validation: { required: true },
                    fieldType: ""
                }
            ])
        }
        return () => {
            mounted = false;
        }
    }, [questionList]);

    function checkIfAlreadyLoggedIn() {
        if (props.isLoggedIn)
            props.history.push(URL_DASHBOARD)
    }

    async function getQuestionList() {
        try {
            setLoading(true);
            let response = await new Api().get(`/admin/question/list`, {
                returnUnhandledError: true,
                returnError: true
            });
            let questionList = response.data.rows.map((item) => {
                let dataValues = { ...item };
                dataValues.label = item.question
                return dataValues
            })
            setQuestionList(questionList);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error:", error);
        }
    }

    const handleSubmit = async (values) => {
        let queAns = [];
        let queAnsObj = {};
        Object.entries(values).forEach(([key, value]) => {
            if (key.substring(0, 3) === "ans" && value) {
                queAnsObj.answer = value
                queAns.push(queAnsObj)
            } else if (key.substring(0, 3) === "sec" && value) {
                queAnsObj = {}
                queAnsObj.questionId = value.id
            }
        });
        var details = {
            'ques_ans': JSON.stringify(queAns),
        };
        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        try {
            setLoading(true);
            let response,
                adminId = localStorage.getItem("adminId");
            response = await new Api().post(`/admin/addquestions/${adminId}`, {
                data: formBody,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                returnUnhandledError: true,
                returnError: true
            })
            dispatch(loginUser({ isLoggedIn: "true", userName: response.data?.first_name && response.data?.first_name + " " + response.data?.last_name, accessToken: response.data.authToken, adminId: response.data.id, access_level: response.data?.accessLevel }))
            props.onSuccess(props.type, { ...props.data, ...details });
            props.history.push(URL_DASHBOARD)
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            console.log(
                "TCL ~ file: Drawer.jsx ~ line 25 ~ formSubmit ~ error",
                error
            );
        }
    };

    function handleQuestionField() {
        setCount([...count, "-"]);
        let indexs = count.length + 2;
        setInputs([...inputs, {
            label: `Security Question ${indexs}`,
            name: `securityQuestion${indexs}`,
            validation: { required: false, object: true },
            fieldType: { type: "AutoComplete", multiple: false, data: questionList }
        },
        {
            label: "Answer",
            name: `answer${indexs}`,
            validation: { required: false },
            fieldType: ""
        },]);
        return indexs
    }

    return (
        <div className={classes.container}>
            <BasicDialog open={open} onClose={props.onClose} {...props}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent className={classes.btnAction}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ values, touched, errors, handleChange, setFieldValue, getFieldProps }) =>
                        (
                            <Form className={classes.root}>
                                {
                                    inputs.map(input =>
                                        <InputWithLabel
                                            className="classes.root"
                                            name={input.name}
                                            fieldType={input.fieldType}
                                            placeholder={input.label}
                                            label={input.label}
                                            type={input.type}
                                            value={values[input.name]}
                                            onChange={handleChange}
                                            onQuestionFieldAdd={handleQuestionField}
                                            setfieldvalue={setFieldValue}
                                            validated={touched[input.name] && errors[input.name]}
                                        />
                                    )
                                }
                                <DialogActions>
                                    <Button
                                        className={classes.buttons}
                                        onClick={props.onClose}
                                    >
                                        Cancel
                                    </Button>
                                    <LoaderButton
                                        type="submit"
                                        className={classes.btnItem}
                                        variant="contained"
                                        loading={loading}
                                        width="120px"
                                        height="40px"
                                    >
                                        {props.submitText}
                                    </LoaderButton>
                                </DialogActions>
                            </Form>)
                        }
                    </Formik>
                </DialogContent>
            </BasicDialog>
        </div>
    );
}

const mapReduxStateToProps = (state) => ({
    isLoggedIn: state.Auth.isLoggedIn,
})

export default connect(mapReduxStateToProps, {
    loginUser
})(withRouter(QuestionDialog));
