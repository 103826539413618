import styled from "styled-components";
import { responsive } from 'Styles/Constants';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';


export const SnackbarWrapper = styled(Snackbar)`
         ${responsive.MOBILE`
                left: auto;
                top:24px;
                right:24px;
            `}
`;
export const SnackbarContentWrapper = styled(SnackbarContent)`
    &.themed {
        background-color: #D85767;
        padding-left    : 30px;

        .notification-message {
            color       : #FFFFFF;
            font-size   : 15px;
            max-width   : calc(100% - 75px);
        }

        .close-icon {
            color : #FFFFFF;
        }
    }
`