import { ACTION_TYPES } from './actions';

const initState = {
    isLoggedIn: localStorage.getItem('isLoggedIn') ? (localStorage.getItem('isLoggedIn') === "true") : false,
    userName: localStorage.getItem('userName') || "",
    accessToken: localStorage.getItem('accessToken') || "",
    adminId: localStorage.getItem('accessToken') || "",
    access_level: localStorage.getItem('access_level') || "",
    socket: null
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_USER:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
                userName: action.userName,
                accessToken: action.accessToken,
                adminId: action.adminId,
                access_level: action.access_level
            };

        case ACTION_TYPES.LOGOUT_USER:
            return {
                ...state,
                isLoggedIn: false
            };

        case ACTION_TYPES.SOCKET_INSTANCE: 
            return {
                ...state,
                socket: action?.socket
            }

        default:
            return state;
    }

}

export default Reducer;